<template>
  <div>
    <b-button variant="outline-primary" @click="modalOpen = true" block>
      {{ translations.txtGenericCopyToVenue }}
    </b-button>

    <wiskModal v-model="modalOpen" size="md" @ok="save" :title="translations.confirmVenueCopyDataTitle" :okDisabled="!valid || disabled" :key="key">
      <wiskInputGroup @errorCountChanged="setValidState" :disabled="disabled">
        <wiskSelect infoTooltipKey="77fda3b6-5c56-4efe-8c6a-f50524d5e692" required :label="translations.txtGenericVenue" v-model="selectedVenueId" :items="availableVenues" fixedWrapper />
        <wiskInput infoTooltipKey="72509470-6557-4f39-bb98-3f7f67686e7c" :label="translations.txtPOSItemsCopyOnlyIfFound" inputType="checkbox" v-model="copyOnlyIfExists" />
      </wiskInputGroup>
    </wiskModal>
  </div>
</template>

<script>


import { mapState, mapActions, mapGetters } from 'vuex'
import api from '@/api'

export default {
  name: 'POSItemsCopyToVenueRowAction',
  emits: ['clearSelection'],
  components: {},
  props: {
    rows: { type: Array, required: true },
    clearAfterEmitOperation: Boolean,
    checkDisabled: Function
  },
  data() {
    return {
      modalOpen: false,
      valid: true,
      copyOnlyIfExists: false,
      selectedVenueId: null,
      disabled: false,
      key: 1
    }
  },
  computed: {
    ...mapState(['translations', 'user', 'venue', 'currentPermissionsByType']),
    ...mapGetters(['permissionsByVenueIdByType', 'siblingVenues']),
    availableVenues() {
      return this.siblingVenues.filter(v => this.getPermissionsByVenueIdByType(v.id).pos_item_manage)
    }
  },
  methods: {
    ...mapActions(['setGlobalAction', 'notify']),
    setValidState(errorCount) {
      this.valid = !errorCount
    },
    getPermissionsByVenueIdByType(venueId) {
      return this.user?.god_mode ? this.currentPermissionsByType || {} : (this.permissionsByVenueIdByType || {})[venueId] || {}
    },
    save() {
      api.copyDataToVenue({
        'origin_venue_id': this.venue.id,
        'destination_venue_id': this.selectedVenueId,
        'data_filter': {
          type: 'filtered_pos_items',
          pos_item_ids: this.rows.map(row => row.id),
          only_if_exists: this.copyOnlyIfExists
        }
      }).then(() => {
        this.$emit('clearSelection')

        this.notify({
          message: this.translations.confirmCopyDataToVenue,
          type: 'success'
        })
      })
    }
  },
  mounted() {
    if (this.checkDisabled) {
      this.disabled = this.checkDisabled(this.rows)
    }
  },
  watch: {
    disabled() {
      this.key++
    }
  }
}
</script>

<style lang="scss"></style>
