/*
  This file is responsible for versions, their schema changes and also upgrade functions.
  Do not change the older versions, just add a new version every time if there are any changes needed.

  To define data types, make sure that you are defining it in dataMapper and also here.

  You only need to put changed data types in your version schema.
  Also if you need to transform data in an update, you can use upgrade function:
  {
    version: 2,
    schema: {
      new_data_type: {
        primaryKey: 'item_id', // optional, default: 'id'
        dbIndex: 'data_type_secondary_id' // optional
      }
    },
    upgrade: tx => {
      return tx.table('new_data_type').toCollection().modify(data => {
        data.test = {}
      });
    }
  }

  *** IMPORTANT
  *** Make sure to keep primaryKey and dbIndex values the same for a data type in both dbMigration.js (for it's last version) and dataMapper.js
*/

export default [
  {
    version: 1,
    schema: {
      accounting_venue_class: {},
      active_item: { primaryKey: 'item_id' },
      allergen: {},
      batch: {},
      batch_cost: {},
      category: {},
      custom_field: {},
      dashboard: { primaryKey: 'venue_id' },
      distributor: {},
      distributor_portal_credential: {},
      draft_invoice: {},
      draft_invoice_extra_line: {},
      draft_invoice_line: { dbIndex: 'draft_invoice_id' },
      family: {},
      gl_account: {},
      inventory: {},
      inventory_location: {},
      inventory_location_measurement: { dbIndex: 'data.item_distributor_id, data.inventory_id' },
      item: { primaryKey: 'item_id' },
      item_algo: { primaryKey: 'item_id' },
      item_cost: { primaryKey: 'item_id' },
      item_stock_prediction: { primaryKey: 'item_id' },
      item_stock_prediction_from_sales: { primaryKey: 'item_id' },
      item_variation: {},
      location: {},
      movement_adjustment_reason: {},
      order_guide: {},
      par_level: { primaryKey: 'item_id' },
      pos_item: {},
      pos_item_cost: {},
      revenue_center: {},
      role: {},
      serving_size: {},
      tax_rate: {},
      transfer_request: {},
      venue: {},
      venue_stats: {},
      web_view_setting: {},
      pos_integration: {},

      // unused data types
      dashboard_high_level: {},
      pos_item_venue_properties: {},
      spout: {},
      item_price_calculation: {},
      container: {}
    }
  },
  {
    version: 2,
    schema: {
      pos_modifier: {}
    }
  },
  {
    version: 3,
    schema: {
      distributor_venue_properties: {}
    }
  }
]
