<template>
  <wiskModal :visible="!!editAction" @hide="setGlobalAction({ type: 'draftInvoiceEdit', action: null })" :title="modalTitle" :fullScreen="isFullWidth"
    hideFooter :warning="!!(draftInvoice?.archived || draftInvoice?.status?.type === 'issue')" size="lg" hideHeaderExtra headerClass="pt-2"
    @goLeft="editAction?.previous?.go({ customFilterName: customFilter?.name })" :showNavigationLeft="!!editAction?.previous?.id"
    :showNavigationRight="!!editAction?.next?.id" @goRight="editAction?.next?.go({ customFilterName: customFilter?.name })">

    <template v-slot:modal-title>
      <div class="d-flex flex-wrap align-items-center" v-if="draftInvoice && firestoreInitialLoadComplete">
        <div class="draft-invoice-title" :class="{ 'w-100': !isFullWidth, 'w-50': isFullWidth, warning: draftInvoice.archived, 'mb-3': !hasScans, 'me-2': !editAction.next }">
          <h4 class="modal-title">
            {{ modalTitle }} - {{ translations.txtMovementSource }}: {{ draftInvoice?.source?.type || '-' }}
          </h4>
          <div v-if="draftInvoice?.distributor_name_detected_from_outside">
            {{ translations.txtScannedInvoicesFintechDistributor }}:
            {{ draftInvoice.distributor_name_detected_from_outside }}
          </div>
        </div>

        <b-form-radio-group class="me-2" size="sm" buttons v-model="selectedEditorTab" name="titleSearchLocal" button-variant="outline-primary">
          <b-form-radio :value="0"> {{ translations.txtGenericEdit }} </b-form-radio>
          <b-form-radio :value="1"> {{ translations.txtGenericTimeline }} </b-form-radio>
        </b-form-radio-group>

        <div class="flex-grow-1"></div>

        <div class="flex-grow-1 d-flex align-items-center justify-content-end">
          <wiskActions :title="translations.txtGenericActions" :actions="pageActions" size="sm" dropdownClass="me-3" />

          <b-button variant="primary" size="sm" v-if="canMarkAsReviewed" class="me-2" @click="setWiskReviewed">
            {{ translations.txtScannedInvoicesWiskReviewed }}
            <icon name="wisk-check" class="ms-1" :scale="0.6" />
          </b-button>

          <b-button v-if="!showFinalApproveButton" :disabled="!canConvert" variant="primary" class="me-2 py-1" @click="convert">
            {{ translations.txtScannedInvoicesConvert }}
            <icon v-show="specialCaseTotalsAlmostDontMatch" name="wisk-information-button" class="ms-2" :scale="0.7" v-tooltip="translations.txtScannedInvoicesTotalsAlmostMatchTooltip" />
          </b-button>
          <b-button v-if="showFinalApproveButton" :disabled="!canAskFinalApproval" variant="primary" class="me-2 py-1" @click="askForApproval">
            {{ translations.txtScannedInvoicesAskForApproval }}
          </b-button>
          <b-button class="me-2" variant="link" size="sm" v-if="draftInvoice?.status?.type === 'converted_to_invoice'" @click="openMovement">
            {{ translations.txtMovementEditViewIntake }}
          </b-button>

          <v-menu :triggers="['hover', 'focus']" v-if="!canConvert && (warnings?.length || draftInvoice?.status?.type === 'issue')">
            <icon class="info-icon text-danger" name="wisk-information-button"></icon>

            <template #popper>
              <b-row noGutters v-if="draftInvoice && !draftInvoice.movement_id && warnings?.length" class="text-danger p-3">
                <b-col cols="12" class="text-bold">
                  {{ translations.txtScannedInvoicesWarningsHeader }}
                </b-col>
                <b-col v-if="draftInvoice.status.type === 'issue'">
                  • {{ statusLabel }}
                </b-col>
                <b-col v-for="warning in warnings" :key="warning.type" cols="12">
                  • {{ translations.groupScannedInvoicesAPIErrors[warning.type] || warning.type }}
                </b-col>
              </b-row>
            </template>
          </v-menu>
        </div>
      </div>
    </template>

    <div class="min-h-440 h-100">
      <div v-if="selectedEditorTab === 0" class="h-100">
        <b-row class="h-100" v-if="initDone && draftInvoice && firestoreInitialLoadComplete" :key="key">
          <b-col v-if="isFullWidth" class="h-100 px-0 d-flex flex-column" cols="12" md="6">
            <nav class="tabs wisk-nav-content-tab">
              <ul class="nav nav-tabs" role="tablist">
                <li v-if="scans?.length" class="nav-item cursor-pointer nav-link" :class="{ active: selectedImageViewerTab === 0 }" @click="selectedImageViewerTab = 0">
                  {{ translations.txtScannedInvoicesViewImage }}
                </li>
                <li v-if="selectedDistributor && ocrTables?.length" class="nav-item cursor-pointer nav-link" :class="{ active: selectedImageViewerTab === 1 }" @click="selectedImageViewerTab = 1">
                  {{ translations.txtScannedInvoicesViewOCR }}
                </li>
                <li class="flex-grow-1"></li>
                <li v-if="user.god_mode && nanonetsLink" class="nav-item nav-link">
                  <a :href="nanonetsLink" target="_blank">{{ isInstantLearningModel ? translations.txtScannedInvoicesNewNanonetsModel : translations.txtScannedInvoicesOldNanonetsModel }}</a>
                </li>
              </ul>
            </nav>
            <div v-if="selectedImageViewerTab === 0 && scans?.length" class="position-relative w-100 h-100" style="overflow: auto;">
              <imagePicker v-if="useImageViewer" preSelectFirst viewOnly :images="scans" :key="draftInvoice.id" @currentImage="onImageSelect" @pdfPageNumber="selectedDocumentPage = $event" ref="imagePicker"
                :regionHighlight="highlightBox" :originalDocumentSize="selectedDocumentDimensions" @regionHighlightClear="highlightBox = null"
                :activeRegions="ocrActiveRegions" @activeRegionSelected="locateLine" />

              <pdfViewer v-if="mounted && !useImageViewer" :regionHighlight="highlightBox" :originalDocumentSize="selectedDocumentDimensions" @regionHighlightClear="highlightBox = null" @numPages="pdfPageCount = $event"
                :activeRegions="ocrActiveRegions" @activeRegionSelected="locateLine" @pageNumber="selectedDocumentPage = $event" :url="scans[0]" @pdfLoaded="pdfFileLoaded = true" listenForWindowResize
                renderText class="p-0" ref="pdfViewer" @pdfPageRendered="onPDFPageRender" @errorInvalidPDF="onChange({ type: 'status', value: { type: 'issue', issue_type: 'unreadable' } })" />
            </div>
            <div v-if="selectedImageViewerTab === 1" class="overflow-auto flex-grow-1">
              <table class="table table-sm" v-for="(ocrTable, index) in ocrTables" :key="index">
                <thead class="position-sticky">
                  <tr scope="row">
                    <th v-for="column in Object.values(ocrTable.longestRow)" :key="column.col" scope="col">
                      <span> {{ column.text }} </span>
                    </th>
                  </tr>
                  <tr scope="row" v-if="user.god_mode">
                    <th v-if="!selectedDistributor?.wisk_distributor?.id" :colspan="Object.keys(Object.values(ocrTable.longestRow).slice(0, 1)[0]).length" class="bg-info">
                      <icon name="wisk-warning" :scale="0.7" class="text-danger" />
                      {{ translations.txtMovementEditNotPublicDistributor }}
                    </th>
                    <template v-else>
                      <th v-for="column in Object.values(ocrTable.data).slice(0, 1)[0]" :key="column.col" scope="col">
                        <div style="display: block;">
                          <b-dropdown :text="ocrOveridesMap[column.text]" size="xs" variant="outline-secondary">
                            <b-dropdown-item v-for="item in ocrOverideTypes" :key="item.id" @click="overrideOCRType(column.text, item.id)" :active="ocrOveridesMap[column.text] === item.id">
                              {{ item.title }}
                            </b-dropdown-item>
                          </b-dropdown>
                          <b-button v-if="ocrOveridesMap[column.text]" size="sm" variant="link" class="px-0" @click="clearOCRType(column.text)">
                            <icon name="wisk-trash" :scale="0.7" class="text-danger" />
                          </b-button>
                        </div>
                      </th>
                    </template>
                    <th cols="1" style="background: none; border-bottom: none;" class="text-center">
                      <b-button size="sm" variant="link" class="px-0" @click="reprocess" v-tooltip="'Reprocess draft invoice'">
                        <icon name="wisk-sync" :scale="1" />
                      </b-button>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(row, rowKey) in Object.values(ocrTable.data).slice(1)" :key="rowKey" scope="row">
                    <td v-for="column in ocrTable.longestRow" :key="`${rowKey}_${column.col}`" scope="col">
                      <span> {{ (row[`col_${column.label}`]?.label === column.label && row[`col_${column.label}`].text) || '' }} </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-col>
          <b-col class="h-100" :key="draftInvoice.id" v-if="draftInvoice" id="scrollerContainer">
            <wiskInputGroup :disabled="disabled">
              <b-row class="mb-2 p-3">
                <b-col v-if="draftInvoice?.distributor_name_detected_from_outside" cols="12" class="mb-4" style="margin-top: -12px">
                  {{ translations.txtScannedInvoicesFintechDistributor }}:
                  {{ draftInvoice.distributor_name_detected_from_outside }}
                </b-col>
                <b-col cols="12" md="4" class="px-1">
                  <wiskInput infoTooltipKey="8b6acc98-04fc-4c7c-8459-d350b38e34b4" :modelValue="draftInvoice.invoice_number" @operation="onChange" operation="invoice_number"
                    :label="translations.txtMovementEditInvoiceNumber" />
                </b-col>
                <b-col cols="12" md="4" class="px-1">
                  <wiskSelect infoTooltipKey="069bb52d-43ec-4a08-ae28-d9cbe5ba879d" :label="translations.txtGenericDistributor" trackBy="id" :items="activeDistributors" required ref="refDistributorComponent"
                    :modelValue="selectedDistributor" :addNewItem="addDistributorConfig" @change="onDistributorChange" operation="distributor_id" colClass="additional-button-presents"
                    :requiredValidationMessageOverride="translations.txtScannedInvoicesDistributorNotSaved" :key="distributorSelectorKey" :helperText="linkedDistributorHelpText" :multiselectOptions="{ preserveSearch: true }">

                    <template v-slot:label-append>
                      <b-button v-if="selectedDistributor" variant="link" size="sm" @click="openDistributor" class="py-0 px-0" style="pointer-events: all;" v-tooltip="linkedDistributorTooltip">
                        <icon name="wisk-link" :scale="0.6" :class="`text-${selectedDistributor.wisk_distributor ? 'primary' : 'secondary'}`" />
                      </b-button>
                    </template>
                  </wiskSelect>

                </b-col>
                <b-col cols="12" md="4" class="px-1">
                  <wiskSelect infoTooltipKey="b0ad730b-e680-42ec-bd71-c30cc49d2aae" :label="translations.txtTaxesScheme" trackBy="id" :items="taxSchemes" required v-if="currentPermissionsByType.tax_rate_assign && venue.taxes_enabled"
                    @operation="onChange" operation="taxes_scheme" :modelValue="draftInvoice.taxes_scheme" :customLabelOverride="taxSchemeSelectorLabelTranslator" />
                </b-col>
                <b-col cols="12" md="4" class="px-1">
                  <wiskInput infoTooltipKey="5e2dcc28-a1f1-457f-9de3-6a05eecbcf39" :label="translations.txtGenericDate" @operation="validateInvoiceDate" operation="date" inputType="datepicker"
                    :modelValue="draftInvoice.date" ref="datePickerRef" :min="venue.last_locked_inventory_date" :max="new Date()" />
                </b-col>
                <b-col cols="12" md="4" class="px-1">
                  <wiskSelect infoTooltipKey="faf965a1-b6c3-45f2-96bf-860b122183bb" :label="translations.txtGenericLocation" trackBy="id" :items="activeLocations" required
                    @operation="onChange" operation="location_id" :modelValue="selectedLocation" />
                </b-col>
                <b-col cols="12" md="4" class="px-1">
                  <wiskInput infoTooltipKey="ea5d3444-e3ce-456b-8892-483a12162b0d" :modelValue="draftInvoice.invoice_total" @operation="onChange" operation="invoice_total" ref="refInvoiceTotalComponent"
                    :label="translations.txtMovementEditInvoiceTotalOnPaper" inputType="number" :decimals="4" decimalsAsNeeded :minDecimals="2" />
                </b-col>
              </b-row>
              <b-row v-if="inLockedPeriod">
                <b-col cols="12" class="px-3">
                  <b-alert variant="warning" show class="mb-0 p-2 py-1 mb-2 text-center">
                    <icon name="wisk-warning" :scale="0.7" class="me-1" />
                    <small>
                      {{ translations.txtScannedInvoicesInLockedPeriod }}
                    </small>
                  </b-alert>
                </b-col>
              </b-row>

              <b-row class="">
                <b-col>
                  <nav class="tabs wisk-nav-content-tab">
                    <ul class="nav nav-tabs" role="tablist">
                      <li class="nav-item cursor-pointer nav-link" :class="{ active: selectedEditorInnerTab === 0 }" @click="selectedEditorInnerTab = 0">
                        {{ translations.txtScannedInvoicesViewItems }} ({{ visibleLines.length }})
                      </li>
                      <li class="nav-item cursor-pointer nav-link" :class="{ active: selectedEditorInnerTab === 1 }" @click="selectedEditorInnerTab = 1">
                        {{ translations.txtScannedInvoicesViewExtraLines }} ({{ visibleExtraLines.length }})
                      </li>
                      <li v-if="venue.accounting_enabled" class="nav-item cursor-pointer nav-link" :class="{ active: selectedEditorInnerTab === 2 }" @click="selectedEditorInnerTab = 2">
                        {{ translations.txtGLAccounts }}
                      </li>
                    </ul>
                  </nav>
                </b-col>
                <!-- <b-col class="" cols="2">

                </b-col> -->
                <b-col class="" cols="4">
                  <b-alert :variant="statusIconMap[draftInvoice.status.type]?.variant" show class="mb-0 p-2 py-1 mb-2 text-center">
                    <icon v-if="statusIconMap[draftInvoice.status.type]" :name="statusIconMap[draftInvoice.status.type].icon" :scale="0.7" class="me-1" />

                    <small>
                      {{ statusLabel }}
                    </small>

                    <b-button v-if="draftInvoice.status.issue_type && fixesByIssueType[draftInvoice.status.issue_type]?.action" size="sm"
                      class="py-0 ms-2" :variant="statusIconMap[draftInvoice.status.type]?.variant" @click="fixesByIssueType[draftInvoice.status.issue_type].action">
                      {{ translations.txtGenericFix }}
                    </b-button>
                    <infoTooltip :helpKey="`draftInvoiceStatus_${draftInvoice.status.type}${draftInvoice.status.issue_type ? '_' + draftInvoice.status.issue_type : ''}`" style="min-width: 0;" :key="draftInvoice?.status?.type" />

                    <b-button v-if="draftInvoice.status.issue_type && fixesByIssueType[draftInvoice.status.issue_type]?.allowClearStatus" size="sm"
                      class="py-0 ms-2 text-success float-end" variant="link" @click="clearStatus" v-tooltip="translations.txtScannedInvoicesStatusResetInfo">
                      {{ translations.txtGenericClear }}
                    </b-button>

                  </b-alert>
                </b-col>
              </b-row>

              <b-row class="bg-white mx-0">
                <b-col class="py-2 d-flex flex-wrap justify-content-between">
                  <wiskInput infoTooltipKey="46b1c110-93a9-4e5d-9d18-ca74ff2af28e" class="mb-0 mb-2-m" ref="searchInput" :label="translations.txtGenericTypeToSearch"
                    v-model="query" showClearButton preventDisabledFromParent style="width: 300px;" />

                  <infoTooltip class="" :scale="1.2" helpKey="scannedInvoiceInfoMessage" />

                  <wiskGridCustomFilterDropdown :customFilters="customFilters" v-model="customFilter" />
                </b-col>

                <b-col cols="12" class="pb-2 d-flex flex-wrap justify-content-between">
                  <div v-if="draftInvoice?.status?.type !== 'converted_to_invoice'">
                    <b-button v-if="Object.keys(batchOperationLinesMap).length !== visibleLines.length" class="me-1" variant="primary" size="sm" @click="selectOrUnselectAllLines(true)">
                      {{ translations.txtGenericSelectAll }}
                    </b-button>
                    <b-button v-if="Object.keys(batchOperationLinesMap).length > 0" variant="primary" size="sm" class="me-1" @click="selectOrUnselectAllLines(false)">
                      {{ translations.txtGenericUnSelectAll }}
                    </b-button>
                    <b-button v-if="Object.keys(batchOperationLinesMap).length > 0" variant="danger" size="sm" @click="batchRemove">
                      {{ translations.txtScannedInvoiceLineBatchRemove }}
                    </b-button>
                  </div>

                  <p v-if="costChangesItems.length" class="m-0 text-danger">{{ costChangesItems.length }} {{ translations.txtGenericCostChanges }}</p>
                </b-col>
              </b-row>
              <div :style="itemsListContainerStyle">
                <draggable v-if="initDone && !query && selectedEditorInnerTab === 0 && !customFilter" class="tab-container px-3 py-2" handle=".drag-sort-scanned-invoice-line" v-model="lines" v-bind="dragOptions" @start="drag = true" @end="saveSortOrder" itemKey="id">
                  <template #item="{ element: item }">
                    <scannedInvoiceLine :expanded="selectedLine && selectedLine.id === item.id" @select="selectLine" :lineItem="item" @selectNextLine="selectNextLine"
                      :disabled="disabled || !selectedDistributor?.id" @operation="onChange" @insertLine="insertLine" @needsResponse="submitIssueMissingInfo" @checkReviewStatus="checkReviewStatus"
                      :distributor="selectedDistributor" :scannedInvoiceId="draftInvoiceId" @locateLine="locateOCRLine" :ocrCellsById="ocrCellsById"
                      :selected="batchOperationLinesMap[item.id]" @toggleBatchState="handleLineBatchState(item.id, $event)" />
                  </template>
                </draggable>

                <div v-if="initDone && (query || customFilter) && selectedEditorInnerTab === 0" class="tab-container px-3 py-2 hide-drag-handle" :key="customFilter?.name || 'all'">

                  <scannedInvoiceLine :expanded="selectedLine && selectedLine.id === item.id" @select="selectLine" :lineItem="item" @selectNextLine="selectNextLine"
                    :disabled="disabled || !selectedDistributor?.id" @operation="onChange" @insertLine="insertLine" :distributor="selectedDistributor" :scannedInvoiceId="draftInvoice.id"
                    @locateLine="locateOCRLine" :ocrCellsById="ocrCellsById" v-for="item in visibleLines" :key="item.random" @needsResponse="submitIssueMissingInfo" />
                </div>
                <div v-if="selectedEditorInnerTab === 1" class="tab-container  px-3 py-2">
                  <scannedInvoiceExtraLineItem v-for="item in visibleExtraLines" :key="item.id" :extraLineItem="item" :disabled="disabled || !selectedDistributor?.id" :distributor="selectedDistributor" @operation="onChange" />
                </div>
                <div v-if="selectedEditorInnerTab === 2" class="tab-container  px-3 py-2">
                  <glAccountsGrid v-if="selectedEditorInnerTab === 2" :lines="lines" :extraLines="extraLines" :gridStyle="{ height: 'calc(100vh - 390px)' }" />
                </div>

                <div style="display: flex; align-items: center;">
                  <b-button v-if="!disabled && (!draftInvoice.movement_id && (selectedEditorInnerTab === 1 || selectedEditorInnerTab === 0))"
                    class="mx-auto my-2" variant="outline-primary" @click="appendLine" v-tooltip="translations.txtScannedInvoicesAddLineItem">
                    <span>{{ translations.txtScannedInvoicesAddLineItem }}</span>
                    <icon style="" name="wisk-plus" :scale="0.7" class="ms-2" />
                  </b-button>
                </div>
              </div>

              <customFields v-if="hasCustomFields" target="draft_invoice" :item="draftInvoice" @operation="onChange" class="mb-1 pb-0" />

              <wiskLoading v-if="!initDone && !query" loading />

              <b-row class="text-center pt-3 bg-body" style="position: sticky; bottom: 0;">
                <b-col cols="3" class="">
                  <div :class="{ 'text-danger attention': draftInvoice.status.type === 'issue' }" class="me-2 mt-4" v-if="draftInvoice.status">


                    <b-dropdown block v-if="(venue?.draft_invoices_settings?.type === 'venue' || user.god_mode || user.is_wisk_user) && draftInvoice.status?.type !== 'issue' && !hasMovementId" variant="outline-danger" toggle-class="text-decoration-none float-end w-100" :text="translations.txtMovementEditSubmitIssue" size="md" up class="me-1">
                      <b-dropdown-item @click="submitIssue('missing_pages', translations.groupMovementEditIssues['missing_pages'])" variant="danger" class="w-100">
                        <span>{{ translations.groupMovementEditIssues['missing_pages'] }}</span>
                      </b-dropdown-item>
                      <b-dropdown-item @click="submitIssue('unreadable', translations.groupMovementEditIssues['unreadable'])" variant="danger" class="w-100">
                        <span>{{ translations.groupMovementEditIssues['unreadable'] }}</span>
                      </b-dropdown-item>
                      <b-dropdown-item @click="submitIssue('missing_information', translations.groupMovementEditIssues['missing_information'])" variant="danger" class="w-100">
                        <span>{{ translations.groupMovementEditIssues['missing_information'] }}</span>
                      </b-dropdown-item>
                    </b-dropdown>

                    <currentViewers v-if="!draftInvoice.movement_id" :draftInvoiceId="draftInvoiceId" />

                  </div>
                </b-col>
                <b-col class="">
                  <h4 v-if="draftInvoice.taxes_scheme !== 'no_taxes'" style="border-right: 1px solid var(--gray-400);">
                    {{ translations.txtGenericSubTotal }} &nbsp; {{ currencyFormat(computedSubTotal) }}
                  </h4>
                </b-col>
                <b-col class="">
                  <h4 v-if="draftInvoice.taxes_scheme !== 'no_taxes'" style="border-right: 1px solid var(--gray-400);">
                    {{ translations.txtGenericTax }} &nbsp; {{ currencyFormat(computedTaxTotal) }}
                  </h4>
                </b-col>
                <b-col class="d-flex" cols="5">
                  <div class="text-right flex-grow-1">
                    <h4>
                      {{ translations.txtGenericTotal }}
                    </h4>
                    <h4>
                      {{ translations.txtMovementEditInvoiceTotalOnPaper }}
                    </h4>
                    <hr class="my-1" />
                    <h4>
                      {{ translations.txtMovementEditInvoiceDifference }}
                    </h4>
                  </div>
                  <div class="ps-2 d-table">
                    <h4 class="d-table-row">
                      <span class="text-right d-table-cell">{{ currencyFormat(computedTotal).split('.')[0] }}</span>
                      <span class="d-table-cell" style="text-align: left;">.{{ currencyFormat(computedTotal).split('.')[1] }}</span>
                    </h4>
                    <h4 class="d-table-row">
                      <span class="text-right d-table-cell">{{ currencyFormat(draftInvoice.invoice_total).split('.')[0] }}</span>
                      <span class="d-table-cell" style="text-align: left;">.{{ currencyFormat(draftInvoice.invoice_total).split('.')[1] }}</span>
                    </h4>
                    <div class="d-table-row">
                      <hr class="d-table-cell border-black" />
                      <hr class="d-table-cell border-black" />
                    </div>
                    <h4 class="d-table-row" :class="{ 'text-danger': Math.abs((draftInvoice.invoice_total || 0) - computedTotal) > 0 }">
                      <span class="text-right d-table-cell pt-1">{{ currencyFormat(Math.abs((draftInvoice.invoice_total || 0) - computedTotal)).split('.')[0] }}</span>
                      <span class="d-table-cell pt-1" style="text-align: left;">.{{ currencyFormat(Math.abs((draftInvoice.invoice_total || 0) - computedTotal)).split('.')[1] }}</span>
                    </h4>
                  </div>
                </b-col>
              </b-row>
            </wiskInputGroup>

          </b-col>
        </b-row>
      </div>
      <div v-if="selectedEditorTab === 1" class="h-100">
        <timeline :filters="[{ type: 'draft_invoice', id: draftInvoiceId }]" parentGridName="ScannedInvoiceTimeline" :gridStyle="{ height: 'calc(100vh - 150px)' }" />
      </div>
    </div>

    <wiskLoading loading v-if="!firestoreInitialLoadComplete" />

    <confirm ref="confirmDialog">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div v-if="confirmationDate" v-html="translations.translate('tplScannedInvoiceDateInFutureDescription', { '{a}': `<span class='mx-1 text-danger'>${confirmationDate}</span>` })"></div>
    </confirm>

  </wiskModal>
</template>

<script>
import { markRaw } from 'vue'
import { mapState, mapActions, mapGetters } from 'vuex'
import isEqual from 'lodash.isequal'
import cloneDeep from 'lodash.clonedeep'
// import { detailedDiff } from 'deep-object-diff'
import merge from 'lodash.merge'
import get from 'lodash.get'
import { DateTime } from 'luxon'
import draggable from 'vuedraggable'
import api from '@/api'
import { formatDate, currencyFormat, stringFilter, compareNumbers, getRandom, getImageSize, guid, prepareItemsForWiskInput, prepareScannedInvoice } from '@/modules/utils'
import timeline from '@/components/timeline/Timeline'
import imagePicker from '@/components/common/ImagePicker'
import scannedInvoiceLine from '@/components/draftInvoices/DraftInvoiceLine'
import scannedInvoiceExtraLineItem from '@/components/draftInvoices/DraftInvoiceExtraLine'
import glAccountsGrid from '@/components/movements/InvoiceGLAccountsGrid'
import infoTooltip from '@/components/common/WiskInfoTooltip'
import pdfViewer from '@/components/viewers/pdf/PDFViewer'
import customFields from '@/components/customFields/CustomFieldsRender'
import currentViewers from '@/components/draftInvoices/DraftInvoiceEditCurrentViewers'

export default {
  name: 'DraftInvoiceEdit',
  components: { imagePicker, scannedInvoiceLine, scannedInvoiceExtraLineItem, draggable, timeline, infoTooltip, glAccountsGrid, pdfViewer, customFields, currentViewers },
  props: { editAction: Object },
  data() {
    return {
      currencyFormat,
      draftInvoiceFromAPI: null,
      pdfFileLoaded: null,
      pdfPageCount: 0,
      ocrData: null,
      ocrCellsById: {},
      debug: 0,
      customFilter: null,
      selectedLine: null,
      selectedDocumentFilename: null,
      selectedDocumentPage: null,
      highlightBox: null, //{ xmax: 545, xmin: 52, ymax: 513, ymin: 55 },
      query: '',
      firstPDFRenderDone: false,
      drag: false,
      initDone: false,
      ocrTables: [],
      ocrOveridesMap: {},
      ocrOverideTypes: prepareItemsForWiskInput(['sku', 'title', 'unit_of_measurement', 'price', 'quantity', 'total']),
      converting: false,
      selectedDistributor: null,
      selectedDistributorId: null,
      draftInvoice: null,
      saveSortOrderTtimeoutId: null,
      searchTimeoutId: null,
      selectedEditorTab: 0,
      selectedImageViewerTab: 0,
      selectedEditorInnerTab: 0,
      key: 1,
      extraLines: [],
      visibleExtraLines: [],
      lines: [],
      visibleLines: [],
      scans: [],
      statusIconMap: {
        inbox: { icon: 'wisk-status-added', variant: 'info' },
        in_processing: { icon: 'wisk-history', variant: 'warning' },
        to_review: { icon: 'wisk-edit', variant: 'primary' },
        converted_to_invoice: { icon: 'wisk-check', variant: 'success' },
        issue: { icon: 'wisk-warning', variant: 'danger' },
        reviewed_by_wisk: { icon: 'wisk-check', variant: 'primary' },
        final_approval_needed: { icon: 'wisk-check', variant: 'primary' }
      },
      onDemandStateIdEntriesByDraftInvoiceId: guid(),
      distributorSelectorKey: 1,
      batchOperationLinesMap: {},
      confirmationDate: null,
      resultId: null,
      mounted: false,
      pageRotationFixMap: {}
    }
  },
  computed: {
    ...mapState([
      'translations',
      'itemVariantsById',
      'locations',
      'user',
      'bottlesById',
      'distributorsById',
      'firestoreInitialLoadComplete',
      'draftInvoicesById',
      'draftInvoiceLinesByDraftInvoiceId',
      'draftInvoiceExtraLinesByDraftInvoiceId',
      'locationsById',
      'currentPermissionsByType',
      'customFieldsByTarget'
    ]),
    ...mapGetters(['activeDistributors', 'venue', 'taxSchemes']),
    isInstantLearningModel() {
      return this.draftInvoice.model_type === 'instant_learning'
    },
    linkedDistributorHelpText() {
      if (this.selectedDistributor?.wisk_distributor && this.selectedDistributor?.wisk_distributor?.title !== this.selectedDistributor?.title) {
        return this.selectedDistributor?.wisk_distributor?.title
      }

      return ''
    },
    linkedDistributorTooltip() {
      if (this.selectedDistributor?.wisk_distributor) {
        return this.translations.translate('tplDistributorPublincLinked', { '{a}': this.selectedDistributor.wisk_distributor.title })
      }

      return this.translations.txtDistributorPublincLinkedNot
    },
    fixesByIssueType() {
      return {
        missing_pages: {
          allowClearStatus: !!this.user.is_wisk_user,
          action: this.openPDFSplitEditorForReupload
        },
        unreadable: {
          action: this.openPDFSplitEditorForReupload,
          allowClearStatus: !!this.user.is_wisk_user
        },
        missing_information: {
          action: () => {
            if (this.$refs.confirmDialog) {
              this.$refs.confirmDialog.confirm({
                callback: () => {
                  this.onChange({ type: 'status', value: { type: 'to_review' } })
                },
                message: this.translations.txtScannedInvoicesMissingInfoFixConfirm,
                title: this.translations.confirmOperationTitle
              })
            }
          }
        }
      }
    },
    nanonetsLink() {
      if (this.resultId) {
        if (this.isInstantLearningModel) {
          return `https://app.nanonets.com/#/ocr/test/f21fae5b-0f54-4e25-abcf-1dea181b93fd/${this.resultId}`
        }
        return `https://app.nanonets.com/#/ocr/test/71dae51f-e962-4c2e-a1d3-1e1680c78956/${this.resultId}`
      }
      return null
    },
    costChangesItems() {
      const costChangesItems = this.lines.filter(i => i.change_per_unit || (i.price_per_unit && i.previous_cost_per_unit && i.price_per_unit !== i.previous_cost_per_unit))
      return costChangesItems
    },
    draftInvoiceComputed() {
      return this.draftInvoicesById[this.draftInvoiceId]
    },
    linesComputed() {
      if (Array.isArray(this.draftInvoiceFromAPI?.lines)) {
        return this.draftInvoiceFromAPI.lines
      }
      return this.draftInvoiceLinesByDraftInvoiceId[this.draftInvoiceId] || []
    },
    extraLinesComputed() {
      if (Array.isArray(this.draftInvoiceFromAPI?.extra_lines)) {
        return this.draftInvoiceFromAPI.extra_lines
      }
      return this.draftInvoiceExtraLinesByDraftInvoiceId[this.draftInvoiceId] || []
    },
    isFullWidth() {
      return !!(this.ocrTables?.length || this.scans?.length)
    },
    hasCustomFields() {
      return !!this.customFieldsByTarget?.draft_invoice?.filter?.(c => !c.archived).length
    },
    itemCreationForbiddenForWiskUser() {
      return this.venue?.draft_invoices_settings?.type === 'wisk_without_items' && this.user.is_wisk_user && !this.user.god_mode
    },
    statusLabel() {
      let label = this.translations.groupScannedInvoiceStatuses[this.draftInvoice.status.type]

      if (this.draftInvoice.status.type === 'issue') {
        label = this.translations.groupMovementEditIssues[this.draftInvoice.status.issue_type]
      }

      return `${label}`
    },
    useImageViewer() {
      return this.scans.length && this.scans[0] && (this.scans.length > 1 || !this.scans[0].endsWith('.pdf'))
    },
    selectedDocumentDimensions() {
      if (this.ocrData && this.selectedDocumentPage && this.selectedDocumentFilename) {
        return get(this.ocrData, `${this.selectedDocumentFilename}.pages.${this.selectedDocumentPage}.dimensions`)
      }
      return null
    },
    ocrActiveRegions() {
      if (this.lines && this.lines.length && this.ocrCellsById) {
        let regions = []
        this.lines.forEach(line => {
          let region = { id: line.id }

          if (line && line.ocr_cells && line.ocr_cells.length) {
            line.ocr_cells.forEach(cell => {
              let found = this.ocrCellsById[cell.value]

              if (found) {
                if (!region.ymin) {
                  region.ymin = found.ymin
                  region.ymax = found.ymax
                }

                if (!region.xmin || region.xmin > found.xmin) {
                  region.xmin = found.xmin
                }
                if (!region.xmax || region.xmax < found.xmax) {
                  region.xmax = found.xmax
                }

                region.page = found.page
              }
            })
            if (region.id && region.xmax) {
              regions.push(region)
            }
          }
        })

        return regions
      }
      return []
    },
    customFilters() {
      let filters = [
        { name: 'unmapped', label: this.translations.txtScannedInvoicesUnmappedItems },
        { name: 'withError', label: this.translations.txtScannedInvoicesViewItemsWithError },
        { name: 'withCostChange', label: this.translations.txtGenericCostChanges },
      ]

      if (this.selectedEditorInnerTab === 0) {
        filters.push({ name: 'needsAttention', label: this.translations.txtScannedInvoicesNeedAttentionItems })
      }

      return filters
    },
    specialCaseTotalsAlmostDontMatch() {
      if (this.draftInvoice?.warnings?.length) {
        return !!this.draftInvoice.warnings.find(w => w && w.type === 'totals_almost_dont_match')
      }

      return false
    },
    warnings() {
      if (this.draftInvoice?.warnings?.length) {
        return this.draftInvoice.warnings.filter(w => w && w.type !== 'totals_almost_dont_match')
      }
      return []
    },
    activeLocations() {
      return this.locations.filter(d => !d.archived)
    },
    hasMovementId() {
      return !!this.draftInvoice.movement_id
    },
    disabled() {
      return !!this.draftInvoice.movement_id || ['inbox', 'in_processing', 'converted_to_invoice'].includes(this.draftInvoice?.status?.type) || (this.draftInvoice?.status?.type === 'final_approval_needed' && this.user.is_wisk_user && !this.user.is_super_user)
    },
    itemsListContainerStyle() {
      const key = `${this.isFullWidth}-${this.hasCustomFields}`,
        valueMap = {
          'true-true': '570px',
          'true-false': '440px',
          'false-true': '650px',
          'false-false': '510px'
        }

      return { 'min-height': `calc(100vh - ${valueMap[key]}` }
    },
    pageActions() {
      let noReupload = this.draftInvoice?.source?.type === 'transfer' || this.draftInvoice?.source?.type === 'distributor',
        canReprocess = ['in_processing', 'inbox'].includes(this.draftInvoice?.status?.type) || this.draftInvoice?.status?.issue_type === 'check_for_multiple_invoices'

      return [
        { key: 4, title: this.translations.txtDistributorManage, variant: 'primary', action: this.openDistributor, hide: !this.selectedDistributorId },
        { key: 5, title: this.translations.txtGLAccountsManage, variant: 'primary', action: this.openGLAccounts },
        { key: 10, title: this.translations.txtTaxesManage, variant: 'primary', action: this.openTaxes },
        { key: 11, title: this.translations.txtScannedInvoicesReupload, variant: 'primary', hide: this.disabled || noReupload, action: this.openPDFSplitEditorForReupload },
        { key: 13, title: this.translations.txtCustomFieldsView, variant: 'primary', action: this.openCustomFields },
        { key: 14, title: this.translations.txtScannedInvoicesReprocess, variant: 'primary', action: this.reprocess, hide: !canReprocess || noReupload || this.disabled },
        { key: 15, title: this.translations.txtScannedInvoicesPDFSplit, variant: 'primary', action: this.openPDFSplitEditor, hide: !this.pdfFileLoaded || this.disabled },
        {
          key: 30,
          title: this.translations.txtGenericArchive,
          icon: 'wisk-archive',
          variant: 'danger',
          hide: !this.currentPermissionsByType.draft_invoice_archive || this.draftInvoice.archived || this.disabled,
          action: this.toggleArchived
        },
        {
          key: 40,
          title: this.translations.txtGenericRestore,
          icon: 'wisk-history',
          variant: 'success',
          hide: !this.currentPermissionsByType.draft_invoice_archive || !this.draftInvoice.archived,
          action: this.toggleArchived
        }
      ]
    },
    inLockedPeriod() {
      if (this.draftInvoice?.date) {
        return this.draftInvoice.date < this.venue.last_locked_inventory_date
      }
      return false
    },
    showFinalApproveButton() {
      return (this.inLockedPeriod || this.venue.draft_invoice_final_approval_needed)
        && this.draftInvoice.status?.type !== 'final_approval_needed'
        && this.user.is_wisk_user
    },
    canConvert() {
      let ok = !!this.currentPermissionsByType.draft_invoice_approve && !this.converting,
        lines = this.lines || [],
        extraLines = this.extraLines || []

      if (ok && this.draftInvoice) {
        ok = ok && !!this.draftInvoice.date
        ok = ok && !!this.draftInvoice.distributor.id
        ok = ok && this.draftInvoice.status?.type !== 'converted_to_invoice'
        ok = ok && this.draftInvoice.status?.type !== 'in_processing'
        ok = ok && this.draftInvoice.status?.type !== 'issue'
        ok = ok && (this.draftInvoice.status?.type !== 'final_approval_needed' || !this.user.is_wisk_user)

        ok = ok && !lines.some(item => !item.item_distributor_id || !item.measurement.quantity || !item.price.value || item.needs_review || item.needs_response || this.getItemArchivedFromVariantId(item.item_distributor_id))
        ok = ok && !extraLines.some(item => !item.reason_id || !item.total)
        ok = ok && !this.draftInvoice.warnings.some(w => w && w.type !== 'totals_almost_dont_match')
      }
      return ok
    },
    canAskFinalApproval() {
      //TODO: deduplicate with canConvert
      let ok = !!this.currentPermissionsByType.draft_invoice_approve && !this.converting,
        lines = this.lines || [],
        extraLines = this.extraLines || []

      if (ok && this.draftInvoice) {
        ok = ok && !!this.draftInvoice.date
        ok = ok && !!this.draftInvoice.distributor.id
        ok = ok && this.draftInvoice.status?.type !== 'converted_to_invoice'
        ok = ok && this.draftInvoice.status?.type !== 'in_processing'
        ok = ok && this.draftInvoice.status?.type !== 'issue'
        ok = ok && this.draftInvoice.status?.type !== 'final_approval_needed'

        ok = ok && !lines.some(item => !item.item_distributor_id || !item.measurement.quantity || !item.price.value || item.needs_review || item.needs_response || this.getItemArchivedFromVariantId(item.item_distributor_id))
        ok = ok && !extraLines.some(item => !item.reason_id || !item.total)
        ok = ok && !this.draftInvoice.warnings.some(w => w && w.type !== 'totals_almost_dont_match')
      }
      return ok
    },
    canMarkAsReviewed() {
      let ok = (this.itemCreationForbiddenForWiskUser || this.user.god_mode) && this.draftInvoice?.status?.type === 'to_review',
        lines = this.lines || []

      if (ok && this.draftInvoice) {
        ok = ok && !lines.some(item => item?.completionStatus === 'needs_attention')
        ok = ok && lines.some(item => item?.completionStatus === 'almost_completed')
        ok = ok && !!this.draftInvoice.date
        ok = ok && !!this.draftInvoice.distributor?.id
        ok = ok && this.draftInvoice.status?.type !== 'issue'
      }
      return ok
    },
    computedTotal() {
      let total = 0

      if (this.draftInvoice) {
        if (this.lines?.length) {
          this.lines.forEach(item => {
            total += item.total || 0
          })
        }

        if (this.extraLines?.length) {
          this.extraLines.forEach(item => {
            total += item.type === 'charge' ? item.total || 0 : -(item.total || 0)
          })
        }
      }

      return total
    },
    computedSubTotal() {
      let total = 0

      if (this.draftInvoice && this.lines && this.lines.length) {
        this.lines.forEach(item => {
          total += get(item, 'subtotal', 0)
        })

        if (this.extraLines && this.extraLines.length) {
          this.extraLines.forEach(item => {
            total += item.type === 'charge' ? get(item, 'subtotal', 0) : -get(item, 'subtotal', 0)
          })
        }
      }
      return total
    },
    computedTaxTotal() {
      return this.computedTotal - this.computedSubTotal
    },
    selectedLocation() {
      if (this.draftInvoice && this.draftInvoice.location_id) {
        return this.locationsById[this.draftInvoice.location_id]
      }
      return this.locations.find(l => l && l.is_default)
    },
    addDistributorConfig() {
      return {
        showButtonIfNoId: true,
        action: (id, searchQuery, value, callbackItemInjected) => {
          this.setGlobalAction({
            type: 'distributorEdit',
            action: {
              id: 0,
              title: searchQuery,
              onChange: distributor => {
                callbackItemInjected(distributor)
              }
            }
          })
        },
        label: this.translations.txtGenericNewDistributor
      }
    },
    draftInvoiceId() {
      return this.editAction?.id || 0
    },
    modalTitle() {
      if (this.draftInvoice) {
        return this.translations.translate('tplScannedInvoicesEdit', { '{a}': formatDate(this.draftInvoice.insert_date) })
      }
      return ''
    },
    hasScans() {
      return !!this.scans?.length || !!this.ocrTables?.length
    },
    dragOptions() {
      return {
        animation: 200,
        disabled: this.visibleLines.length !== this.lines.length,
        sort: true,
        ghostClass: 'ghost'
      }
    },
    isDistributorInvoice() {
      return get(this.draftInvoice, 'source.type') === 'distributor'
    }
  },
  methods: {
    ...mapActions(['setGlobalAction', 'setOnDemandState', 'notify']),
    validateInvoiceDate(operation) {
      // if (DateTime.fromISO(operation.value) > DateTime.local()) {
      //   if (this.$refs.confirmDialog) {
      //     this.confirmationDate = formatDate(operation.value)
      //     this.$refs.confirmDialog.confirm({
      //       callback: () => {
      //         this.onChange(operation)
      //         this.confirmationDate = null
      //       },
      //       cancelCallback: () => {
      //         this.confirmationDate = null
      //       },
      //       title: this.translations.txtScannedInvoiceDateInFutureTitle,
      //       message: '',
      //       okText: this.translations.txtGenericConfirm
      //     })
      //   }
      // } else {
      this.onChange(operation)
      // }
    },
    handleLineBatchState(id, val) {
      if (val) {
        this.batchOperationLinesMap[id] = true
      } else {
        delete this.batchOperationLinesMap[id]
      }
    },
    selectOrUnselectAllLines(select) {
      this.visibleLines.forEach(line => {
        this.handleLineBatchState(line.id, select)
      })
    },
    openCustomFields() {
      this.setGlobalAction({ type: 'customFields', action: { target: 'draft_invoice' } })
    },
    clearStatus() {
      if (this.$refs.confirmDialog) {
        this.$refs.confirmDialog.confirm({
          callback: () => {
            this.onChange({ type: 'status', value: { type: 'to_review' } })
          },
          okText: this.translations.txtGenericClear,
          message: this.translations.txtScannedInvoicesStatusResetConfirm,
          title: this.translations.confirmOperationTitle
        })
      }
    },
    batchRemove() {
      if (this.$refs.confirmDialog) {
        this.$refs.confirmDialog.confirm({
          callback: () => {
            const calls = Object.keys(this.batchOperationLinesMap).map(id => {
              const line = this.lines.find(l => l.id === id)
              this.$store.commit('mutateDraftInvoiceLine', { ...line, REMOVE: true })

              return api.updateDraftInvoiceLine(this.draftInvoiceId, id, [{ type: 'delete' }])
            })
            Promise.all(calls)
              .then(() => {
                this.batchOperationLinesMap = {}
              })
          },
          message: this.translations.txtScannedInvoiceLineBatchRemoveConfirmMessage,
          title: this.translations.txtScannedInvoiceLineBatchRemove
        })
      }
    },
    onImageSelect(img) {
      this.selectedDocumentFilename = img.substr(img.lastIndexOf('/') + 1).replace(/\./g, '_')
    },
    selectLine(id) {
      this.selectedLine = this.lines.find(l => l.id === id)
      this.locateOCRLine(id)
    },
    selectNextLine(id) {
      let index = this.lines.findIndex(l => l.id === id)

      if (index >= 0 && index < this.lines.length) {
        let nextIndex = (index + 1) % this.lines.length,
          nextLine = this.lines[nextIndex]

        if (nextLine) {
          this.selectLine(nextLine.id)
        }
      }
    },
    openPDFSplitEditor() {
      this.setGlobalAction({ type: 'pdfSplitEdit', action: { draftInvoices: [this.draftInvoice], onChange: this.onPDFSplitDone } })
    },
    openPDFSplitEditorForReupload() {
      this.setGlobalAction({ type: 'pdfSplitEdit', action: { draftInvoice: this.draftInvoice, onChange: this.onReuploadDone } })
    },
    onReuploadDone(result) {
      this.notify({
        type: 'success',
        message: this.translations.txtScannedInvoicesReuploadDone
      })

      if (result?.draft_invoice_ids?.length) {
        this.onChange({ type: 'archive', value: true })
        this.setGlobalAction({ type: 'draftInvoiceEdit', action: null })

        setTimeout(() => {
          this.setGlobalAction({ type: 'draftInvoiceEdit', action: { id: result.draft_invoice_ids[0] } }) //redirect to the new draft invoice
        }, 3000)
      }
    },
    onPDFSplitDone(result) {
      console.log('pdfSplitEdit result', result)

      this.notify({
        type: 'success',
        message: this.translations.txtScannedInvoicesPDFSplitDone
      })

      // setTimeout(() => {
      //   this.setGlobalAction({ type: 'draftInvoiceEdit', action: null })
      // }, 2000)
    },
    locateLine(id) {
      let element = document.getElementById(`draft-invoice-line-${id}`)

      this.selectedLine = this.lines.find(l => l.id === id)

      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })

        setTimeout(() => {
          element.classList.add('highlight')

          setTimeout(() => {
            element.classList.remove('highlight')
          }, 500)
        }, 700)
      }
      this.highlightBox = null
    },
    locateOCRLine(lineId) {
      let zzz = {},
        box = {},
        pageNumber = 1,
        line = this.lines.find(l => l.id === lineId),
        locateLineOCRCellIds = (line && line.ocr_cells && line.ocr_cells.length && line.ocr_cells.map(c => c.value).filter(c => c && this.ocrCellsById[c])) || []

      this.highlightBox = null

      locateLineOCRCellIds.forEach(id => {
        if (this.debug) {
          zzz[id] = this.ocrCellsById[id]
        }

        let found = this.ocrCellsById[id]
        if (found) {
          pageNumber = found.page

          if (!box.ymin) {
            box.ymin = found.ymin
            box.ymax = found.ymax
          }

          if (!box.xmin || box.xmin > found.xmin) {
            box.xmin = found.xmin
          }
          if (!box.xmax || box.xmax < found.xmax) {
            box.xmax = found.xmax
          }
        }
      })

      if (box.ymin) {
        // if (this.debug) {
        //   console.log('zzz', JSON.parse(JSON.stringify(zzz)))
        //   console.log('box', JSON.parse(JSON.stringify(box)))
        //   console.log('this.$refs.pdfViewer', this.$refs.pdfViewer)
        // }

        if (this.$refs?.pdfViewer?.reset && this.$refs.pdfViewer.goTo && this.selectedDocumentPage !== pageNumber) {
          this.$refs.pdfViewer.reset()
          this.$refs.pdfViewer.goTo(pageNumber)
        }

        setTimeout(() => {
          this.highlightBox = box
        }, 100)
      }
      // else {
      //   console.log('id not found in ocrCellsById', locateLineOCRCellIds, this.ocrCellsById)
      // }
    },
    onPDFPageRender() {
      this.highlightBox = null

      if (this.selectedLine && !this.firstPDFRenderDone) {
        this.firstPDFRenderDone = true
        this.locateOCRLine(this.selectedLine.id)
      }
    },
    taxSchemeSelectorLabelTranslator(item) {
      return this.translations.groupTaxSchemes[item.id]
    },
    askForApproval() {
      if (this.$refs.confirmDialog) {
        this.$refs.confirmDialog.confirm({
          callback: () => {
            this.onChange({ type: 'status', value: { type: 'final_approval_needed' } })
          },
          message: this.translations.txtScannedInvoicesAskForApprovalMessage,
          title: this.translations.confirmOperationTitle
        })
      }
    },
    convert() {
      if (this.$refs.confirmDialog && this.canConvert) {
        let message = this.translations.confirmScannedInvoicesConvert
        if (this.specialCaseTotalsAlmostDontMatch) {
          let difference = Math.abs((parseFloat(this.draftInvoice.invoice_total || 0) - parseFloat(this.computedTotal || 0))).toFixed(2)
          message = this.translations.translate('tplConfirmScannedInvoicesConvertTotalsAlmostMatch', { '{a}': difference })
        }
        this.$refs.confirmDialog.confirm({
          callback: () => {
            if (!this.converting) {
              this.converting = true
              this.onChange({ type: 'convert_to_invoice' })
            }
          },
          message,
          title: this.translations.txtScannedInvoicesConvert
        })
      }
    },
    toggleArchived() {
      this.onChange({ type: 'archive', value: !this.draftInvoice.archived })
    },
    appendLine() {
      this.addItem().then(result => {
        setTimeout(() => {
          this.selectLine(result.id)
        }, 500)

        setTimeout(() => {
          let element = document.getElementById('scrollerContainer')

          if (element) {
            element.scrollTo({
              top: 100000,
              behavior: 'smooth'
            })
          }
        }, 1000)
      })
    },
    addItem() {
      if (this.selectedEditorInnerTab === 1) {
        return api.updateDraftInvoiceExtraLine(this.draftInvoiceId, 0, { type: 'quantity', value: 1 })
      }

      let operations = [
        { type: 'measurement', value: { type: 'unit', quantity: 0 } },
        { type: 'price', value: { type: 'manual', value: 0, measurement: { quantity: 1, unit_of_measurement: 'unit' } } }
      ]

      return api.updateDraftInvoiceLine(this.draftInvoiceId, 0, operations)
    },
    insertLine(payload) {
      const line = this.lines.find(l => l.id === payload.lineId),
        position = payload.position || 'after',
        sortOrder = position === 'after' ? line.sort_order + 1 : line.sort_order - 1

      this.addItem().then(result => {
        setTimeout(() => {
          result.sort_order = sortOrder
          result.completionStatus = this.getCompletionStatus(result)

          this.lines = this.lines.filter(l => l.id !== result.id)
          this.lines.push(result)
          this.lines.sort((a, b) => compareNumbers(a.sort_order, b.sort_order))

          this.saveSortOrder().then(() => {
            this.selectLine(result.id)
          })
        }, 600)
      })
    },
    onDistributorChange(distributor) {
      let autoConfirm = this.selectedDistributor?.id === distributor?.id || !this.selectedDistributor?.id,
        operations = []

      if (this.$refs.confirmDialog) {
        this.$refs.confirmDialog.confirm({
          callback: () => {
            this.selectedDistributor = distributor
            this.selectedDistributorId = distributor?.id
            this.populateOCROverrideMap()

            operations.push({ type: 'distributor_id', value: this.selectedDistributorId })

            if (!autoConfirm) {
              operations.push({ type: 'reprocess' })
            }

            this.onChange(operations)
          },
          cancelCallback: () => {
            this.distributorSelectorKey++
          },
          message: this.translations.confirmScannedInvoicesDistributorChange,
          title: this.translations.confirmOperationTitle,
          autoConfirm
        })
      }
    },
    onChange(operation) {
      console.log('this.draftInvoiceId', this.draftInvoiceId, operation)
      api.updateDraftInvoice(this.draftInvoiceId, operation).then(result => {
        if (this.editAction.onChange) {
          this.editAction.onChange(result)
        }
        console.log('result', result)
        this.$store.commit('mutateDraftInvoice', prepareScannedInvoice(result, this.$store.state))
        this.converting = false
      })
    },
    submitIssue(type, text) {
      if (this.$refs.confirmDialog) {
        this.$refs.confirmDialog.confirm({
          callback: () => {
            this.onChange({ type: 'status', value: { type: 'issue', issue_type: type } })
          },
          message: this.translations.translate('txtMovementEditSubmitIssueConfirmationText', { '{a}': text }),
          title: this.translations.txtMovementEditSubmitIssue
        })
      }
    },
    submitIssueMissingInfo() {
      this.onChange({ type: 'status', value: { type: 'issue', issue_type: 'missing_information' } })
    },
    checkReviewStatus(updatedLine) {
      if (this.draftInvoice.status.issue_type === 'missing_information') {
        let needsResponse = this.lines.filter(l => l.id !== updatedLine.id).some(l => l.needs_response)
        if (!needsResponse) {
          this.onChange({ type: 'status', value: { type: 'to_review' } })
        }
      }
    },
    setWiskReviewed() {
      this.onChange({ type: 'status', value: { type: 'reviewed_by_wisk' } })
    },
    openMovement() {
      this.setGlobalAction({ type: 'movementEdit', action: { id: this.draftInvoice.movement_id }, resetBeforeOpen: true })
    },
    openGLAccounts() {
      this.setGlobalAction({ type: 'glAccounts', action: {} })
    },
    openTaxes() {
      this.setGlobalAction({ type: 'taxes', action: {} })
    },
    openDistributor() {
      this.setGlobalAction({ type: 'distributorEdit', action: { id: this.selectedDistributorId, onChange: this.onDistributorChange } })
    },
    saveSortOrder() {
      clearTimeout(this.saveSortOrderTtimeoutId)

      return new Promise((resolve, reject) => {
        this.drag = false

        this.saveSortOrderTtimeoutId = setTimeout(() => {
          api.setDraftInvoiceItemsOrder({ sort_orders: this.lines.map(l => ({ id: l.id, sort_order: l.sort_order })), id: this.draftInvoiceId })
            .then(resolve)
            .catch(reject)
        }, 300)
      })
    }
    ,
    setFilterItems(caller) {
      if (this.debug) {
        console.log('setFilterItems caller', caller)
      }
      clearTimeout(this.searchTimeoutId)
      this.searchTimeoutId = setTimeout(() => {
        if (this.query || this.customFilter) {
          if (this.selectedEditorInnerTab === 0) {
            this.visibleLines = this.lines.filter(item => stringFilter('contains', item.title, this.query)).map(i => markRaw({ ...i, random: getRandom() }))
            if (this.customFilter) {
              if (this.customFilter.name === 'unmapped') {
                this.visibleLines = this.visibleLines.filter(line => !line.item_distributor_id)
              }
              if (this.customFilter.name === 'withError') {
                this.visibleLines = this.visibleLines.
                  filter(item => !item.item_distributor_id || !item.measurement.quantity || !item.price.value || item.needs_review || item.needs_response || this.getItemArchivedFromVariantId(item.item_distributor_id))
              }
              if (this.customFilter.name === 'needsAttention') {
                this.visibleLines = this.visibleLines.filter(line => line.needs_review || line.needs_response)
              }
              if (this.customFilter.name === 'withCostChange') {
                this.visibleLines = this.costChangesItems
              }
            }
          }
          if (this.selectedEditorInnerTab === 1) {
            this.visibleExtraLines = this.extraLines.filter(item => stringFilter('contains', item.title, this.query))

            if (this.customFilter) {
              if (this.customFilter.name === 'unmapped') {
                this.visibleExtraLines = this.visibleExtraLines.filter(line => !line.reason_id)
              }
              if (this.customFilter.name === 'withError') {
                this.visibleExtraLines = this.visibleExtraLines.filter(item => !item.reason_id || !item.total)
              }
              if (this.customFilter.name === 'needsAttention') {
                this.visibleExtraLines = this.visibleExtraLines.filter(line => line.needs_review || line.needs_response)
              }
            }
          }
        } else {
          this.visibleLines = this.lines.map(i => markRaw({ ...i, random: getRandom() }))
          this.visibleExtraLines = this.extraLines
        }
        if (this.debug) {
          console.log('this.visibleLines', this.visibleLines)
        }
      }, 300)

    },
    getItemTitleFromLine(line) {
      let variant = (line.item_distributor_id && this.itemVariantsById[line.item_distributor_id]) || {},
        itemId = variant.item_id,
        item = (itemId && this.bottlesById[itemId]) || line,
        title = item.title || ''

      return title
    },
    getCompletionStatus(line) {
      let variant = (line.item_distributor_id && this.itemVariantsById[line.item_distributor_id]) || {},
        itemId = variant.item_id,
        item = (itemId && this.bottlesById[itemId]) || {}

      const commonConditionsMet =
        get(line, 'measurement.quantity') &&
        get(line, 'price.value') &&
        !line.needs_review &&
        !line.needs_response &&
        item &&
        !item.archived

      if (commonConditionsMet && line.item_distributor_id) {
        return 'completed'
      }
      if (commonConditionsMet && line.title) {
        return 'almost_completed'
      }
      return 'needs_attention'
    },
    getItemArchivedFromVariantId(variantId) {
      let variant = this.itemVariantsById[variantId] || {},
        itemId = variant.item_id,
        item = (itemId && this.bottlesById[itemId]) || {},
        archived = item.archived || false

      return archived
    },
    fixPDFPageRotation(pageNumber) {
      if (this.pageRotationFixMap[pageNumber]) {
        if (this.debug) {
          console.log('SET ROTATION TO MATCH OCR', `Page: ${pageNumber}`, `fixed: ${this.pageRotationFixMap[pageNumber]}`)
        }
        if (this.$refs?.pdfViewer?.setRotationFromOutside) {
          this.$refs.pdfViewer.setRotationFromOutside(this.pageRotationFixMap[pageNumber])
        }
      }
    },
    loadOCR() {
      api.ocrResultDraftInvoice(this.draftInvoiceId).then(response => {
        this.resultId = response?.results?.[0]?.result?.[0]?.id
        this.ocrData = {}
        this.ocrTables = []
        let localData = {},
          ocrTables = [],
          cellsById = {}

        if (response && Array.isArray(response.results)) {
          for (let i = 0; i < response.results.length; i++) {
            let resultWrapper = response.results[i]

            if (resultWrapper && Array.isArray(resultWrapper.result)) {
              for (let j = 0; j < resultWrapper.result.length; j++) {
                let result = resultWrapper.result[j],
                  fileName = null,
                  page = result.page + 1

                this.pageRotationFixMap[page] = 0

                if (result.rotation === 90) {
                  this.pageRotationFixMap[page] = 270
                }
                if (result.rotation === 270) {
                  this.pageRotationFixMap[page] = 90
                }


                if (result.input) {
                  fileName = result.input.substr(result.input.lastIndexOf('/') + 1).replace(/\./g, '_')

                  localData[fileName] = localData[fileName] || {}
                  localData[fileName].pages = localData[fileName].pages || {}
                  localData[fileName].pages[result.page + 1] = localData[fileName].pages[result.page + 1] || {}
                  let urls = resultWrapper.signed_urls[result.filepath],
                    img = null
                  if (urls) {
                    img = urls.original_with_long_expiry

                    if (img) {
                      getImageSize(img).then(dimensions => {
                        localData[fileName].pages[result.page + 1].dimensions = dimensions
                      })
                    }
                  }
                }
                if (Array.isArray(result.prediction)) {
                  let tables = result.prediction.filter(r => r.type === 'table')

                  for (let h = 0; h < tables.length; h++) {
                    let table = tables[h]

                    if (table && table.cells) {
                      ocrTables[i] = ocrTables[i] || { data: {} }

                      table.cells.forEach(cell => {
                        if (cell && cell.row_label !== 'junk_row') {
                          if (!cell.label) {
                            cell.label = cell.col
                          }

                          ocrTables[i].data[`row_${j}_${h}_${cell.row}`] = ocrTables[i].data[`row_${j}_${h}_${cell.row}`] || {}
                          ocrTables[i].data[`row_${j}_${h}_${cell.row}`][`col_${cell.label}`] = cell
                          cellsById[cell.id] = { ...cell, fileName, page: result.page + 1 }
                        }
                      })

                      ocrTables[i].longestRow = {}
                      Object.values(ocrTables[i].data).forEach((value) => {
                        if (Object.keys(value).length > Object.keys(ocrTables[i].longestRow).length) {
                          ocrTables[i].longestRow = merge({}, value)
                        }
                      })

                      if (this.isInstantLearningModel) {
                        let row_0_0_0 = {}
                        Object.values(ocrTables[i].longestRow).forEach(c => {
                          c.text = c.label
                          row_0_0_0[`col_${c.label}`] = c
                        })
                        ocrTables[i].data = {
                          row_0_0_0,
                          ...ocrTables[i].data
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          this.ocrData = localData
          this.ocrCellsById = cellsById
          this.ocrTables = ocrTables.filter(z => !!z)

          console.log('ocrTables', ocrTables)

          this.autoSwitchSelectedImageViewerTab()
        }
      })
    },
    populateOCROverrideMap() {
      this.ocrOveridesMap = {}

      if (this.selectedDistributor?.wisk_distributor?.invoice_custom_headers) {
        this.selectedDistributor?.wisk_distributor?.invoice_custom_headers.forEach(header => {
          this.ocrOveridesMap[header.value] = header.type
        })
      }
    },
    clearOCRType(text) {
      if (this.selectedDistributor?.wisk_distributor?.id) {
        let value = this.ocrOveridesMap[text],
          operation = {
            type: 'invoice_custom_header_delete',
            value
          }

        this.loading = true

        api.updateWiskDistributor(this.selectedDistributor?.wisk_distributor?.id, operation).then(updated => {
          this.selectedDistributor.wisk_distributor = updated
          this.populateOCROverrideMap()
        }).finally(() => {
          this.loading = false
        })
      }
    },
    overrideOCRType(value, type) {
      if (this.selectedDistributor?.wisk_distributor?.id) {
        this.ocrOveridesMap[value] = type

        let operation = {
          type: 'invoice_custom_header_add',
          value: { type, value }
        }

        this.loading = true

        api.updateWiskDistributor(this.selectedDistributor?.wisk_distributor?.id, operation).then(updated => {
          this.selectedDistributor.wisk_distributor = updated
          this.populateOCROverrideMap()
        }).finally(() => {
          this.loading = false
        })

      }
    },
    reprocess() {
      if (this.$refs.confirmDialog) {
        this.$refs.confirmDialog.confirm({
          callback: () => {
            this.onChange({ type: 'reprocess' })

            this.notify({
              type: 'info',
              message: 'Reprocess started. It can take up to a few minutes for the invoice to be reprocessed.'
            })
          },
          message: this.translations.confirmScannedInvoicesReprocess,
          title: this.translations.confirmOperationTitle
        })
      }

    },
    init(draftInvoice, caller) {
      if (this.debug) {
        console.log('init', caller, draftInvoice)
      }

      if (draftInvoice) {
        draftInvoice.status = draftInvoice.status || {}

        this.draftInvoice = draftInvoice

        if (Array.isArray(this.draftInvoice?.scans)) {
          this.scans = this.draftInvoice.scans.filter(s => !!s)
        }

        if (this.draftInvoice.distributor?.id && this.draftInvoice.distributor?.id !== this.selectedDistributorId) {
          this.selectedDistributor = this.distributorsById[this.draftInvoice.distributor?.id] || this.draftInvoice.distributor
          this.selectedDistributorId = this.selectedDistributor?.id ?? null
          this.populateOCROverrideMap()
        }

        if (!this.initDone) {
          this.loadOCR()
          this.autoSwitchSelectedImageViewerTab()

          if (this.lines?.length) {
            this.selectNextLine(this.lines[this.lines.length - 1].id)
          }

          setTimeout(() => {
            this.$refs.refInvoiceTotalComponent?.triggerValidationCheck?.()
            this.$refs.refDistributorComponent?.triggerValidationCheck?.()

            if (!this.selectedDistributorId && this.$refs.confirmDialog) {
              this.$refs.confirmDialog.confirm({
                hideOK: true,
                cancelCallback: () => { },
                message: this.translations.txtScannedInvoicesDistributorNotSavedMessage,
                title: this.translations.txtFilterMissingDistributor,
              })
            }
          }, 500)
        }

        if (!draftInvoice.date) {
          setTimeout(() => {
            if (this.$refs?.datePickerRef?.getInputComponentRef()) {
              this.$refs.datePickerRef.getInputComponentRef().localDate = DateTime.local().toJSDate()
            }
          }, 500)
        }

        this.initDone = true
      }
    },
    autoSwitchSelectedImageViewerTab() {
      if (!this.scans.length || (this.ocrTables.length && this.isDistributorInvoice)) {
        this.selectedImageViewerTab = 1
      } else {
        this.selectedImageViewerTab = 0
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.mounted = true
    }, 0)
  },
  beforeUnmount() {
    if (this.draftInvoiceId && !this.draftInvoice?.movement_id) {
      api.draftInvoiceLeave(this.draftInvoiceId)
    }

    clearTimeout(this.searchTimeoutId)
    clearTimeout(this.saveSortOrderTtimeoutId)
  },
  watch: {
    draftInvoiceComputed: {
      immediate: true,
      handler(draftInvoiceComputed, previousDraftInvoiceComputed) {
        let copy = draftInvoiceComputed && cloneDeep(draftInvoiceComputed),
          copyPrevious = previousDraftInvoiceComputed && cloneDeep(previousDraftInvoiceComputed) || {}

        console.log('draftInvoiceComputed', draftInvoiceComputed)

        if (copy) {
          if (!isEqual(copy, copyPrevious)) {
            this.init(copy, 'draftInvoiceComputed')
          }
        } else if (this.draftInvoiceId) {
          api.getDraftInvoice(this.draftInvoiceId).then(draftInvoiceFromAPI => {
            if (draftInvoiceFromAPI) {
              this.init(draftInvoiceFromAPI, 'draftInvoiceComputed from API')
              this.draftInvoiceFromAPI = draftInvoiceFromAPI
            }
          }).catch(() => {
            this.setGlobalAction({ type: 'draftInvoiceEdit', action: null })
          })
        }
      }
    },
    linesComputed: {
      immediate: true,
      handler(linesComputed) {
        this.lines = linesComputed.map(l => ({ ...l, title: this.getItemTitleFromLine(l), completionStatus: this.getCompletionStatus(l), sort_order: l.sort_order * 10 }))
        this.lines.sort((a, b) => compareNumbers(a.sort_order, b.sort_order))
        this.setFilterItems('linesComputed')
      }
    },
    extraLinesComputed: {
      immediate: true,
      handler(extraLinesComputed) {
        this.extraLines = extraLinesComputed.map(l => ({ ...l, sort_order: l.sort_order * 10 }))
        this.extraLines.sort((a, b) => compareNumbers(a.sort_order, b.sort_order))
        this.setFilterItems('extraLinesComputed')
      }
    },
    useImageViewer: {
      immediate: true,
      handler(useImageViewer) {
        if (!useImageViewer && this.scans && this.scans[0]) {
          this.selectedDocumentFilename = this.scans[0].substr(this.scans[0].lastIndexOf('/') + 1).replace(/\./g, '_')
        }
      }
    },
    query() {
      this.setFilterItems('query watcher')
    },
    customFilter() {
      this.setFilterItems('customFilter watcher')
    },
    selectedDistributorId() {
      this.key++
    },
    bottlesById() {
      this.setFilterItems('bottlesById watcher')
    },
    selectedDocumentPage(selectedDocumentPage) {
      this.fixPDFPageRotation(selectedDocumentPage)
    },
    editAction: {
      immediate: true,
      handler(editAction) {
        if (editAction?.navigationExtraData?.customFilterName) {
          this.customFilter = this.customFilters.find(f => f.name === editAction.navigationExtraData.customFilterName)
        }
      }
    }
  }
}
</script>

<style lang="scss">
@media (min-width: 768px) {
  #scrollerContainer {
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.tab-container {
  background: white;
}
</style>
