<template>
  <span>
    <component :is="componentType" :emitFast="emitFast" :class="innerComponentClass" :style="innerComponentStyle" v-bind="$attrs"
      @update:modelValue="$emit('update:modelValue', $event)" @itemFieldChange="$emit('itemFieldChange', $event)" ref="inputComponennt"
      @blur="$emit('blur', $event)" @valid="$emit('valid', $event)" showZero @change="emitOperation" />
    <small v-if="itemValue">{{ translations.txtGenericValue }}: {{ currencyFormat(itemValue) }}</small>
    <small v-if="measurementInfo" class="text-dark" style="display: block; margin-top: -5px;">
      {{ translations.txtVenueBottlesMeasurement }}: {{ measurementInfo }}
    </small>
  </span>
</template>

<script>
import { mapState } from 'vuex'
import { currencyFormat, getPriceByMeasurement } from '@/modules/utils'
import unitComponent from '@/components/inventories/InventoryEntryInputUnit'
import caseComponent from '@/components/inventories/InventoryEntryInputCase'
import sliderComponent from '@/components/inventories/InventoryEntryInputSlider'
import scaleComponent from '@/components/inventories/InventoryEntryInputScale'
import manualComponent from '@/components/inventories/InventoryEntryInputManual'
import partialComponent from '@/components/inventories/InventoryEntryInputPartial'

export default {
  name: 'InventoryEntryInput',
  components: { unitComponent, caseComponent, sliderComponent, scaleComponent, manualComponent, partialComponent },
  emits: ['update:modelValue', 'itemFieldChange', 'blur', 'valid', 'operation'],
  props: {
    inputType: {
      validator(value) {
        return ['unit', 'case', 'slider', 'scale', 'manual', 'partial'].includes(value)
      }
    },
    infoMeasurement: Object,
    emitFast: Boolean,
    innerComponentClass: String,
    innerComponentStyle: Object,
    operation: String
  },
  computed: {
    ...mapState(['translations']),
    componentType() {
      return `${this.inputType}Component`
    },
    measurementInfo() {
      if (this.infoMeasurement?.unit_of_measurement) {
        return `${this.infoMeasurement.alias ? this.infoMeasurement.alias + ' - ' : ''}${this.infoMeasurement.quantity} ${this.infoMeasurement.unit_of_measurement}`
      }

      return ''
    },
    itemValue() {
      return getPriceByMeasurement(this.$attrs.item?.price?.price_per_unit, this.$attrs.modelValue, this.$attrs.item?.measurement)
    }
  },
  methods: {
    currencyFormat,
    emitOperation(value) {
      if (this.operation) {
        this.$emit('operation', { type: this.operation, value })
      }
    },
    triggerValidationCheck() {
      this.$refs?.inputComponennt?.triggerValidationCheck()
    }
  }
}
</script>
