import Highcharts from 'highcharts'
import loadStock from 'highcharts/modules/stock'
import loadHighchartsMore from 'highcharts/highcharts-more'
import { formatDate } from '@/modules/utils.js'

export const setHighchartsTheme = () => {
  const style = getComputedStyle(document.getElementById('app')),
    textColor = style.getPropertyValue('--wisk-highcharts-text-color'),
    gridColor = style.getPropertyValue('--wisk-highcharts-grid-color')
  Highcharts.theme = {
    colors: [0, 1, 2, 3, 4, 5, 6, 7].map(index => style.getPropertyValue(`--wisk-highcharts-color-${index}`)),
    chart: {
      backgroundColor: style.getPropertyValue('--wisk-highcharts-background-color'),
      dataLabels: {
        color: 'red',
        style: {
          textOutline: 'none',
          color: 'red',
          fontWeight: 'normal'
        }
      }
    },
    xAxis: {
      labels: {
        style: {
          color: textColor
        }
      },
      minorGridLineColor: textColor,
      minorTickColor: textColor,
      lineColor: textColor,
      gridLineColor: gridColor,
      tickColor: textColor
    },
    yAxis: {
      labels: {
        style: {
          color: textColor
        }
      },
      minorGridLineColor: textColor,
      minorTickColor: textColor,
      lineColor: textColor,
      gridLineColor: gridColor,
      tickColor: textColor
    },
    legend: {
      itemStyle: {
        color: textColor
      }
    },
    plotOptions: {
      bar: {
        dataLabels: {
          color: textColor
        }
      },
      series: {
        animation: false,
        dataLabels: {
          style: {
            textOutline: 'none',
            color: textColor,
            fontWeight: 'normal'
          }
        }
      }
    }
  }
  Highcharts.setOptions({
    ...Highcharts.theme,
  })
}

setHighchartsTheme()
Highcharts.dateFormats = {
  DoMMMMYYYYhmmA: timestamp => formatDate(timestamp),
  DoMMMMYYYY: timestamp => formatDate(timestamp, { format: 'DDD' }),
  DoMMMYYYY: timestamp => formatDate(timestamp, { format: 'DDD' }),
  DoMMM: timestamp => formatDate(timestamp, { format: 'DDD' })
}

Highcharts.setOptions({
  ...Highcharts.theme,
  accessibility: { enabled: false }
})
loadStock(Highcharts)
loadHighchartsMore(Highcharts)

export default Highcharts
