<template>
  <wiskInputGroup @errorCountChanged="setValidState" class="px-2 fill-width-height invite-to-venues-selected-rows-action-container text-center"
    fieldsetClass="bg-white" :legend="translations.txtUsersInviteUsers" :key="key">

    <wiskSelect infoTooltipKey="83129de4-e8df-495b-a027-3925ccaa89f5" :label="translations.txtGenericVenue" @change="selectedVenues = $event" :items="availableVenues"
      class="mb-3" required multiple />

    <wiskSelect infoTooltipKey="c9e76e92-f80c-4a3c-8ee5-d3c284e760cf" :label="translations.txtGenericRole" v-model="selectedRoleId" :items="availableRoles"
      class="mb-3" required />

    <b-button size="sm" variant="link" @click="submit" class="mx-auto text-primary" v-tooltip="translations.txtGenericSubmit" :disabled="!valid">
      <icon class="ms-1 d-inline" style="margin-top: -1px;" scale="1.4" name="wisk-check" />
    </b-button>

  </wiskInputGroup>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  name: 'InviteUsersSelectedRowsAction',
  emits: ['submit'],
  components: {},
  props: {
    rows: { type: Array, required: true },
    clearAfterEmitOperation: Boolean,
    checkDisabled: Function,
  },
  data() {
    return {
      valid: true,
      selectedRoleId: null,
      selectedVenues: null,
      key: 1
    }
  },
  computed: {
    ...mapState(['translations', 'venuesHighLevel']),
    ...mapGetters([]),
    availableVenues() {
      return this.venuesHighLevel.filter(venue => !this.checkDisabled(venue))
    },
    availableRoles() {
      if (!this.selectedVenues) return []
      let commonRoles = this.selectedVenues[0].roles.map(role => ({ ...role }))
      const roleExists = (roles, roleToCheck) =>
          roles.some(role => role.id === roleToCheck.id)
      for (let i = 1; i < this.selectedVenues.length; i++) {
          commonRoles = commonRoles.filter(role => roleExists(this.selectedVenues[i].roles, role))
      }
      return commonRoles
    },
    invitations() {
      let invitations = []

      this.rows.forEach(row => {
        this.selectedVenues.forEach(v => {
          invitations.push({
            venue_id: v.id,
            email: row.email,
            role_id: this.selectedRoleId
          })
        })
      })

      return invitations
    }
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    setValidState(errorCount) {
      this.valid = !errorCount
    },
    submit() {
      if (this.valid && this.invitations.length) {
        this.$emit('submit', this.invitations)
        if (this.clearAfterEmitOperation) {
          this.selectedRoleId = null
          this.selectedVenue = null
          this.key++
        }
      }
    }
  },
  created() { },
  watch: {}
}
</script>

<style lang="scss">

</style>
