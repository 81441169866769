<template>
  <wiskSelect infoTooltipKey="8cfc56d4-9fbb-4567-a02a-5b46732c191e" v-if="localData && localData.length" :label="translations.txtGenericFromDate" :modelValue="selectedItem" required @update:modelValue="onChange"
    :items="items" :multiselectOptions="multiselectOptions" class="" trackBy="id" fixedWrapper disableTooltip>
    <template v-slot:afterList>
      <b-button v-if="!inventoriesHighLevelLoadAllComplete" variant="primary" class="m-1" @click="getInventories">
        {{ translations.txtInventoryLoadAll }}
      </b-button>
    </template>
  </wiskSelect>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { DateTime } from 'luxon'
import merge from 'lodash.merge'
import { formatDate, compareNumbers } from '@/modules/utils'

export default {
  name: 'InventoryDateSelector',
  emits: ['change'],
  components: {},
  props: {
    inputData: {
      type: Array,
      validator: data => {
        if (Array.isArray(data) && (!data.length || (data.length && data[0] && data[0].id && data[0].date))) {
          return true
        }
        return false
      }
    },
    selectedInventoryFrom: {
      type: Object,
      validator: data => {
        if (data.id && data.date) return true
        return false
      }
    }
  },
  data() {
    return {
      selectedItem: null,
      localData: [],
      items: []
    }
  },
  computed: {
    ...mapState(['translations', 'venue', 'inventoriesHighLevelLoadAllComplete']),
    multiselectOptions() {
      return {
        groupValues: 'data',
        groupLabel: 'month',
        trackBy: 'id',
        selectedLabel: '',
        selectLabel: '',
        deselectLabel: '',
        style: {},
        maxHeight: 450,
        label: 'title',
        allowEmpty: false,
        multiple: false
      }
    }
  },
  methods: {
    ...mapActions(['getInventories']),
    onChange(id) {
      const found = this.localData.find(z => z.id === id)
      this.selectedItem = merge({}, found, { title: formatDate(found.date) })
    },
    groupByDate(data) {
      let months = []

      data.forEach(item => {
        let month = formatDate(item.date, { format: 'LLLL yyyy' }),
          found = months.find(d => d.month === month)

        if (!found) {
          // prettier-ignore
          months.push({
            month,
            date: DateTime.fromJSDate(item.date).startOf('month').toJSDate(),
            data: []
          })
          found = months.find(d => d.month === month)
        }

        found.data.push(merge({}, item, { title: formatDate(item.date) }))
        found.data.sort((a, b) => compareNumbers(b.date, a.date))
      })
      months.sort((a, b) => compareNumbers(b.date, a.date))
      return months
    }
  },
  mounted() {
    if (this.selectedInventoryFrom) {
      this.selectedItem = merge({}, this.selectedInventoryFrom, { title: formatDate(this.selectedInventoryFrom.date) })
    }
  },
  watch: {
    inputData: {
      handler(inputData) {
        this.localData = merge([], inputData || [])
          .filter(z => !z.archived)
          .sort((a, b) => compareNumbers(b.date, a.date))
          .map(i => ({ ...i, $isDisabled: i.$isDisabled || Boolean(i.lock_data) }))

      const lastLockedInventoryIndex = this.localData.findIndex(i => i.date?.getTime() === this.venue.last_locked_inventory_date?.getTime())
      if (lastLockedInventoryIndex > -1) {
        this.localData[lastLockedInventoryIndex].$isDisabled = false
      }

        this.items = this.groupByDate(this.localData)

        if (this.localData && this.localData.length > 1) {
          if (!this.selectedItem && !this.localData[0].lock_data) {
            this.selectedItem = merge({}, this.localData[0], { title: formatDate(this.localData[0].date) })
            this.$emit('change', this.selectedItem)
          }
        }
      },
      immediate: true
    },
    selectedItem() {
      this.$emit('change', this.selectedItem)
    }
  }
}
</script>

<style lang="scss">

</style>
