<template>
  <div>
    <wiskGrid :rowData="rowData" :columnDefs="gridColumns" :gridOptions="options" :gridStyle="{ height: 'calc(100vh - 185px)' }"
      resetRows bottomAggregationRow parentGridName="ItemSales" trackBy="row_id"/>

    <wiskLoading :loading="loading" />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import api from '@/api'
import { currencyFormat, convertUM, getMeasurementType, formatNumber, toFixed, getBaseValueFromMeasurement, getMeasurementShortName } from '@/modules/utils'
import wiskGrid from '@/components/grids/WiskGrid'


export default {
  name: 'ItemSales',
  components: { wiskGrid },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      rowData: []
    }
  },
  computed: {
    ...mapState(['translations']),
    ...mapGetters(['defaultUMWeight', 'defaultUMVolume']),
    measurementType() {
      return getMeasurementType(this.item?.measurement)
    },
    baseUM() {
      if (this.measurementType === 'weight') {
        return 'g'
      }

      if (this.measurementType === 'volume') {
        return 'ml'
      }

      return this.measurementType
    },
    displayUM() {
      if (this.measurementType === 'weight') {
        return this.defaultUMWeight
      }
      if (this.measurementType === 'volume') {
        return this.defaultUMVolume
      }

      return this.measurementType
    },
    gridColumns() {
      return [
        {
          colId: 'title',
          tooltipField: 'title',
          cellRenderer: 'CellText',
          sortOrder: 0,
          headerName: this.translations.txtGenericPOSItem,
          field: 'title',
          wiskBottomAggregation: this.translations.txtGenericTotal,
          truncate: true,
          keyCreator: params => params.value.formatted,
          cellRendererParams: {
            translate: this.translations.translate,
            extraButtons: [
              {
                id: 1,
                action: id => {
                  this.setGlobalAction({ type: 'posItemEdit', action: { id } })
                },
                icon: 'wisk-edit'
              }
            ],
            infoComponentType: 'extraButtons'
          },
          valueGetter: params => ({
            id: params?.data?.pos_item_id,
            readonly: true,
            input_value: params?.data?.title
          })
        },
        {
          colId: 'quantity',//units
          sortOrder: 1,
          headerName: this.translations.txtGenericQuantity,
          field: 'quantity',//units
          cellRendererParams: { decimals: 2 },
          wiskGetBottomAggregationValue: rowData => rowData.quantity,
          valueFormatter: params => formatNumber(params.value, { decimals: 2, decimalsAsNeeded: true })
        },
        {
          colId: 'total',
          sortOrder: 5,
          headerName: `${this.translations.txtGenericSales} (${window.WiskGlobals.currency})`,
          width: 100,
          field: 'total',
          cellClass: ['text-end'],
          headerClass: ['text-end'],
          cellRendererParams: { decimals: 2 },
          wiskGetBottomAggregationValue: rowData => rowData.total,
          valueFormatter: params => currencyFormat(params.value)
        },
        {
          headerName: `${this.translations.txtGenericSales} (${getMeasurementShortName(this.displayUM) })`,
          colId: 'asDisplayUM',
          field: 'asDisplayUM',
          sortOrder: 2,
          cellClass: ['text-end'],
          headerClass: ['text-end'],
          cellRendererParams: { decimals: 2 },
          wiskGetBottomAggregationValue: rowData => rowData.asDisplayUM,
          valueFormatter: params => toFixed(params.value, 2),
          width: 100
        },
        {
          headerName: `${this.translations.txtGenericSales} (${this.translations.txtGenericBottles})`,
          colId: 'bottle',
          field: 'bottle',
          sortOrder: 3,
          cellClass: ['text-end'],
          headerClass: ['text-end'],
          cellRendererParams: { decimals: 2 },
          wiskGetBottomAggregationValue: rowData => rowData.bottle,
          valueFormatter: params => toFixed(params.value, 2),
          width: 100
        }
      ]
    },
    options() {
      return {}
    }
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    getData() {
      this.loading = true
      api
        .salesPerpetual(this.item.item_id)
        .then(result => {
          this.rowData = []

          if (result?.sales) {
            this.rowData = result.sales.map(sale => {
              sale = sale || {}
              sale.bottle = sale.bottle || {}
              sale.bottle.item_id = sale.bottle.item_id || sale.bottle.bottle_id
              return {
                title: sale.title,
                pos_item_id: sale.pos_item_id,
                row_id: sale.plu_number,
                quantity: sale.quantity, //should be units
                total: sale.total,
                asDisplayUM: convertUM({ from: { unit_of_measurement: this.baseUM, quantity: sale.bottle.quantity }, to: this.displayUM }),
                bottle: sale.bottle.quantity / getBaseValueFromMeasurement(this.item.measurement)
              }
            })
            this.loading = false
          }
        })
        .catch(error => {
          this.loading = false
          console.log('error', error)
        })
    }
  },
  mounted() {
    this.getData()
  }
}
</script>

<style lang="scss"></style>
