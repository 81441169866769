<template>
  <wiskModal size="lg" @ok="handleChanges" preventOKClose :okDisabled="loading || !valid || !title.length || (hasHiddenFields && !pos?.id && !user.god_mode)"
    :okText="translations.txtGenericSave" :visible="!!editAction" @hide="setGlobalAction({ type: 'POSEdit', action: null })" :hideFooter="selectedTab" headerClass="pe-2">

    <template v-slot:modal-header>
      <h5 class="modal-title">{{ pos ? translations.txtPOSIntegrationEdit : translations.txtPOSIntegrationAdd }}</h5>
      <b-form-radio-group v-if="pos?.id" buttons v-model="selectedTab" button-variant="outline-primary" size="sm" class="d-inline-block mx-auto">
        <b-form-radio :value="0"> {{ translations.txtGenericEdit }} </b-form-radio>
        <b-form-radio :value="1"> {{ translations.txtPosItemsGridPosCategoryMenu }} </b-form-radio>
        <b-form-radio :value="2"> {{ translations.txtPosItemsGridPosCategorySale }} </b-form-radio>
      </b-form-radio-group>
      <wiskActions :title="translations.txtGenericActions" :actions="pageActions" class="d-inline-block"/>
    </template>

    <template v-if="selectedTab === 0">
      <wiskInputGroup @errorCountChanged="setValidState" style="min-height: 500px;" ref="inputGroup" :key="key">
        <wiskSelect infoTooltipKey="e6c99409-5e29-4cde-97ec-1cb98dcee9c4" :label="translations.txtVenueOperationsPOSType" @change="onPOSTypeInput"
          :modelValue="pos && pos.type" required operation="pos_type" :items="items" :multiselectOptions="{}" :disabled="!!pos?.id" ref="posTypeSelector" />

        <wiskInput v-if="!hideTitle" infoTooltipKey="987959c3-2fbf-4af7-9879-a1eefaca7eff" :label="translations.txtPOSIntegrationNickname" required v-model="title" />

        <p v-if="needsWiskMember" :class="helpLink ? 'mb-0' : 'mb-3'">
          {{ translations.txtPOSItemIntegrationNeedsSuperUser }}
        </p>
        <div v-if="helpLink">
          <b-button :href="helpLink" target="_blank" variant="link" class="px-0 mb-3">
            {{ translations.txtPOSItemIntegrationSetup }}
          </b-button>
        </div>

        <div v-if="currentPermissionsByType.venue_settings_manage || user.god_mode">
          <!-- eslint-disable vue/no-use-v-if-with-v-for-->

          <div v-for="(field, name) in extraFields" :key="name + localPos.type">
            <wiskInput v-if="field.type !== 'select' && field.type !== 'array' && (field.availableForNormalUsers || user.god_mode) && (!field.conditional_render || !!localPosParams[field.conditional_render])"
              :label="field.title" @update:modelValue="onChange($event, name)" :operation="name" :infoTooltipKey="`${selectedPOSType}_${name}`"
              :inputType="field.type" :required="!field.optional" :helperText="field.helperText" :parentGridName="'GridMultiSelectInputPosEdit_' + name" :modelValue="localPosParams[name]"
              :items="(gridMultiSelectItemsMapper[field.itemsKey || pos?.type]) || []" :thousandsSeparator="''" />

            <wiskSelect v-if="field.type === 'select' && (field.availableForNormalUsers || user.god_mode) && (!field.conditional_render || !!localPosParams[field.conditional_render])"
              :label="field.title" :operation="name" :modelValue="localPosParams[name]" @update:modelValue="onChange($event, name)" :items="field.items" class="mb-3"
              :helperText="field.helperText" :required="!field.optional" :infoTooltipKey="`${selectedPOSType}_${name}`" />

            <div v-if="field.type === 'array' && Array.isArray(localPosParams[name]) && (field.availableForNormalUsers || user.god_mode)">

              <div v-for="(inArrayData, inArrayDataIndex) in localPosParams[name]" :key="inArrayDataIndex" style="background-color: #ebebeb;  border-radius: 3.7785px;" class="position-relative pt-3 px-2 pb-1 mb-2">

                <div v-for="(innerField, innerFieldName) in field.fields" :key="innerFieldName + localPos.type + inArrayDataIndex">

                  <wiskInput v-if="innerField.type !== 'select'" :operation="name" :modelValue="inArrayData[innerFieldName]" :thousandsSeparator="''" :infoTooltipKey="`${selectedPOSType}_${innerFieldName}`"
                    :label="innerField.title" @update:modelValue="onChange($event, name, field.type, inArrayDataIndex, innerFieldName)" :items="(gridMultiSelectItemsMapper[innerField.itemsKey || pos?.type]) || []"
                    :inputType="innerField.type" class="mb-3" :required="!innerField.optional" :helperText="innerField.helperText" :parentGridName="'GridMultiSelectInputPosEdit_' + name" />

                  <wiskSelect :helperText="innerField.helperText" :infoTooltipKey="`${selectedPOSType}_${innerFieldName}`"
                    v-if="innerField.type === 'select'" @update:modelValue="onChange($event, name, field.type, inArrayDataIndex, innerFieldName)"
                    :label="innerField.title" :operation="name" :modelValue="inArrayData[innerFieldName]"
                    :items="innerField.items" class="mb-3" :required="!innerField.optional" />

                </div>

                <b-button v-if="field.allowAddRemove" @click="localPosParams[name].splice(inArrayDataIndex, 1)" variant="link" v-tooltip="translations.txtGenericRemove" class="pos-extra-field-remove">
                  <icon class="text-danger" name="wisk-delete" :scale="1" />
                </b-button>
              </div>
              <b-button v-if="field.allowAddRemove" @click="localPosParams[name].push({ ...field.seed })">
                <icon class="" name="wisk-plus" :scale="1" />
                {{ translations.txtGenericAdd }}
              </b-button>
            </div>
          </div>
        </div>

        <div v-if="selectedPOSType === 'square'" class="d-flex align-items-center">
          <b-button variant="primary" @click="goToSquareOauth2" :disabled="!(pos && pos.id)">
            {{ translations.txtGenericSetUp }}
          </b-button>
          <p class="ms-1 mb-0" v-if="!(pos && pos.id)">{{ translations.txtPOSIntegrationOauthDescription }}</p>
        </div>
        <div v-if="selectedPOSType === 'clover'" class="d-flex align-items-center" style="max-height: 300px">
          <b-button variant="primary" @click="goToCloverOauth2" :disabled="!(pos && pos.id)">
            {{ translations.txtGenericSetUp }}
          </b-button>
          <p class="ms-1 mb-0" v-if="!(pos && pos.id)">{{ translations.txtPOSIntegrationOauthDescription }}</p>
        </div>
        <div v-if="selectedPOSType === 'shopify'">
          <b-button variant="primary" target="_blank" rel="noreferrer" v-if="localPosParams?.shop_name" :href="shopifyShopName">
            {{ translations.txtGenericSetUp }}
          </b-button>
        </div>
        <div v-if="selectedPOSType === 'lightspeed_oauth2'" class="d-flex align-items-center">
          <b-button variant="primary" @click="goToLightspeedOauth2" :disabled="!(pos && pos.id)">
            {{ translations.txtGenericSetUp }}
          </b-button>
          <p class="ms-1 mb-0" v-if="!(pos && pos.id)">{{ translations.txtPOSIntegrationOauthDescription }}</p>
        </div>
        <div v-if="selectedPOSType === 'lightspeed_kseries'" class="d-flex align-items-center">
          <b-button variant="primary" @click="goToLightspeedKSeries" :disabled="!(pos && pos.id)">
            {{ translations.txtGenericSetUp }}
          </b-button>
          <p class="ms-1 mb-0" v-if="!(pos && pos.id)">{{ translations.txtPOSIntegrationOauthDescription }}</p>
        </div>
        <div v-if="selectedPOSType === 'kounta_oauth2'" class="d-flex align-items-center">
          <b-button variant="primary" @click="goToKountaOauth2" :disabled="!(pos && pos.id)">
            {{ translations.txtGenericSetUp }}
          </b-button>
          <p class="ms-1 mb-0" v-if="!(pos && pos.id)">{{ translations.txtPOSIntegrationOauthDescription }}</p>
        </div>
        <div v-if="selectedPOSType === 'veloceapi' && user.god_mode">
          <b-button variant="primary" @click="getVeloceApiLocations" class="position-relative" :disabled="loadingLocations">
            <wiskLoading v-if="loadingLocations" loading />
            {{ translations.txtPOSExtraVeloceApiFindLocation }}
          </b-button>
          <wiskModalMultiSelect :visible="veloceApiLocationsVisible" trackBy="id" :title="translations.txtPOSExtraVeloceApiSelectLocation" :items="veloceApiLocations"
            @update:modelValue="selectedVeloceApiLocation = $event; veloceApiLocationsVisible = false" @hide="veloceApiLocationsVisible = false" itemLabel="title" v-if="veloceApiLocations?.length" />
        </div>
        <div v-if="selectedPOSType === 'myr'" class="d-flex align-items-center" style="margin-top: -10px;">
          <b-button variant="link" @click="onChange(guid(), 'api_key')">{{ translations.txtPOSExtraGenerateAPIKey }}</b-button>
          <span> {{ translations.txtPOSExtraPasteAPIKey }} </span>
        </div>
        <div v-if="selectedPOSType === 'rpowerapi' && user.god_mode">
          <wiskSelect trackBy="mid" :label="translations.txtPOSExtraRPowerApiSelectStore" :items="rPowerStores" :modelValue="pos?.client_code?.mid" required @select="rPowerStoreChanged" />
        </div>

        <wiskInput v-if="venueComputed.support_sales_conversion" infoTooltipKey="434f562f-6bff-48db-abda-d0c16a6b6b7f" :label="translations.txtPOSIntegrationCurrencyConversation" inputType="number" :decimals="4" decimalsAsNeeded :minDecimals="2" v-model="currencyConversion" />

        <wiskLoading :loading="loading" />
      </wiskInputGroup>

      <confirm ref="confirmDialog" size="md" autofocus>
        <template v-slot:modal-footer-left-side>
          <b-button class="wisk-modal-button me-2 mb-2" v-if="archivedDuplicateIntegration" variant="outline-primary" @click="restoreArchivedDuplicateIntegration">
            {{ translations.txtPOSIntegrationRestoreInactive }}
          </b-button>
        </template>
      </confirm>
    </template>

    <wiskGrid v-else-if="selectedTab === 1" :rowData="menuCategories" :columnDefs="menuCategoriesColumns" parentGridName="menuCategoriesGrid" :gridStyle="{ height: 'calc(100vh - 100px)' }" :header="{ hideHeader: true }" />
    <wiskGrid v-else-if="selectedTab === 2" :rowData="saleCategories" :columnDefs="saleCategoriesColumns" parentGridName="saleCategoriesGrid" :gridStyle="{ height: 'calc(100vh - 100px)' }" :header="{ hideHeader: true }" />
  </wiskModal>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import api from '@/api'
import { isValidEmail, arrayToObjectById, guid } from '@/modules/utils'
import wiskModalMultiSelect from '@/components/common/WiskModalMultiSelect'
import posExtraFields from './posExtraFields'

export default {
  name: 'POSEdit',
  components: { wiskModalMultiSelect },
  props: {
    editAction: { type: Object }
  },
  data() {
    return {
      POSBrands: [],
      posExtraFields: null,
      veloceApiModalVisible: false,
      selectedVeloceApiLocation: null,
      veloceApiValid: false,
      veloceApiLocations: [],
      veloceApiLocationsVisible: false,
      loading: false,
      localPos: {},
      localPosParams: {}, //AKA pos client code (for some reason)
      extraFields: {},
      selectedPOSType: null,
      posBrandId: null,
      valid: false,
      key: 1,
      gridMultiSelectItemsMapper: {
        square: [],
        cloverModifiers: [],
        veloceapiDivisions: []
      },
      title: '',
      hideTitle: true,
      rPowerStores: [],
      loadingLocations: false,
      selectedTab: 0,
      saleCategories: [],
      menuCategories: [],
      archivedDuplicateIntegration: null,
      currencyConversion: 0
    }
  },
  computed: {
    ...mapState(['translations', 'venue', 'currentPermissionsByType', 'user', 'posIntegrations']),
    ...mapGetters(['posTypes']),
    shopifyShopName() {
      if (this.localPosParams.shop_name) {
        let shopName = this.localPosParams.shop_name.replace(/ /g, '-'),
          secondary = this.localPosParams.secondary ? ',secondary-true' : '',
          venueId = this.venueComputed.id

        return `https://${shopName}.myshopify.com/admin/oauth/authorize?client_id=2c842e2abaa7b877304d589f334cd9e6&scope=read_orders,read_customers&redirect_uri=https://web.wisk.ai/public/redirect/shopify&state=venue_id-${venueId}${secondary}`
      }

      return ''
    },
    categoriesColumns() {
      return [
        { headerName: this.translations.txtGenericTitle, sortOrder: 100, colId: 'itemName', field: 'title', suppressSortingToolPanel: true },
        {
          headerName: this.translations.txtPOSIntegrationCategoriesAutoExclude,
          sortOrder: 200,
          colId: 'autoExclude',
          cellRenderer: 'CellCheckbox',
          cellClass: ['mt-1'],
          cellRendererParams: {
            updateValue: (payload) => {
              this.updateAutoExclude({
                operations: [{
                  type: 'auto_exclude',
                  value: payload.value
                }]
              }, payload.id, this.selectedTab === 1 ? 'menu' : 'sale')
            }
          },
          valueGetter: params => ({
              id: params.data?.id,
              input_value: !!params.data?.auto_exclude
            }),
          suppressSortingToolPanel: true
        },
      ]
    },
    menuCategoriesColumns() {
      return this.categoriesColumns
    },
    saleCategoriesColumns() {
      return this.categoriesColumns
    },
    hasHiddenFields() {
      return this.selectedPOSType !== 'clover' && (this.selectedPOSType === 'rpowerapi' || Object.values(this.extraFields).some(item => !item.availableForNormalUsers && !item.optional))
    },
    needsWiskMember() {
      return this.selectedPOSType && (this.selectedPOSType === 'clover' || this.hasHiddenFields)
    },
    pageActions() {
      return [
        {
          key: 1, title: this.translations.txtPOSIntegrationRetry, icon: 'wisk-redo', variant: 'primary',
          hide: !['expired', 'failed', 'called_off'].includes(this.pos?.status?.type) || this.pos?.is_archived,
          action: () => this.save([{
            type: 'pos_integration_status',
            value: { type: 'retry' }
          }])
        },
        { key: 2, title: this.translations.txtGenericDeactivate, icon: 'wisk-archive', hide: !this.pos || this.pos?.is_archived, variant: 'danger', action: this.archive },
        {
          key: 3, title: this.translations.txtGenericActivate, icon: 'wisk-history', hide: !this.pos || !this.pos?.is_archived, variant: 'success', action: () => this.save([{ type: 'restore' }])
        },
      ]
    },
    secondary() {
      return !!(this.editAction.id === 2)
    },
    posTypesById() {
      return arrayToObjectById(this.posTypes)
    },
    helpLink() {
      if (this.selectedPOSType && this.posTypesById[this.selectedPOSType] && this.posTypesById[this.selectedPOSType].article_url) {
        return this.posTypesById[this.selectedPOSType].article_url
      }
      return ''
    },
    venueComputed() {
      if (this.editAction && this.editAction.venue) {
        return this.editAction.venue
      }
      return this.venue
    },
    pos() {
      return this.editAction.data
    },
    validations() {
      return {
        email: { type: 'email', validator: isValidEmail, message: this.translations.txtValidationEmail }
      }
    },
    items() {
      return this.posTypes
    }
  },
  methods: {
    ...mapActions(['setGlobalAction', 'updateVenue']),
    guid,
    updateAutoExclude(operations, id, type) {
      if (type === 'menu') {
        api.updateMenuCategory(id, operations).then(response => {
          const index = this.menuCategories.findIndex(c => c.id === response.id)
          this.menuCategories[index] = response
        })
      } else {
        api.updateSaleCategory(id, operations).then(response => {
          const index = this.saleCategories.findIndex(c => c.id === response.id)
          this.saleCategories[index] = response
        })
      }
    },
    archive() {
      if (this.$refs.confirmDialog) {
        this.$refs.confirmDialog.confirm({
          callback: () => {
            this.save([{ type: 'archive' }])
          },
          message: this.translations.txtPOSIntegrationArchiveDescription,
          title: this.translations.txtGenericDeactivate,
        })
      }
    },
    handleChanges() {
      const operations = [
        { value: this.localPosParams, type: 'client_code' }
      ]
      if (!this.pos?.id) {
        operations.push({ value: this.localPos.type, type: 'pos_type' })
      }
      if (this.title !== this.pos?.title) {
        operations.push({ value: this.title, type: 'title' })
      }
      if (this.currencyConversion) {
        operations.push({ value: this.currencyConversion, type: 'currency_conversion' })
      }
      if (this.pos?.id) {
        if (this.$refs.confirmDialog && this.editAction.data.is_reimport_supported) {
          this.$refs.confirmDialog.confirm({
            callback: () => {
              this.setGlobalAction({ type: 'salesReimport', action: { integration: this.editAction.data, disableIntegration: true, callback: (selectedPosIntegration, since) => {
                    if (selectedPosIntegration) {
                      operations.push({ type: 'reimport_sales', value: since })
                      this.save(operations)
                    } else {
                      this.save(operations)
                    }
                  } } })
            },
            cancelCallback: () => {
              this.save(operations)
            },
            okText: this.translations.txtGenericYes,
            cancelText: this.translations.txtGenericNo,
            message: this.translations.confirmPOSIntegrationReimportSalesData,
            title: this.translations.txtSalesRefreshImport,
          })
        } else {
          this.save(operations)
        }
      } else {
        this.save(operations)
      }
    },
    save(operations, posIntegrationId = this.pos?.id || 0, reopen) {
      this.loading = true
      api.updatePosIntegration(posIntegrationId, { operations })
        .then(result => {
          if (typeof this.editAction.onChange === 'function') {
            this.editAction.onChange(result)
          }
          this.setGlobalAction({ type: 'POSEdit', action: null })
          if (reopen || (['clover', 'kounta_oauth2', 'square', 'lightspeed_oauth2', 'lightspeed_kseries'].includes(this.selectedPOSType) && !this.pos)) {
            this.setGlobalAction({ type: 'POSEdit', action: { id: result.id, data: result } })
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    restoreArchivedDuplicateIntegration() {
      const archivedIntegration = this.archivedDuplicateIntegration
      this.$refs.confirmDialog.cancel()
      this.save([{ type: 'restore' }], archivedIntegration.id, true)
    },
    goToLightspeedOauth2() {
      let tabOpen = window.open('about:blank', 'newtab')

      this.loading = true
      api
        .lightspeedAuth({ pos_integration_id: this.pos.id })
        .then(result => {
          if (result && result.url) {
            tabOpen.location = result.url
          }
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    goToLightspeedKSeries() {
      let tabOpen = window.open('about:blank', 'newtab')

      this.loading = true
      api
        .lightspeedKSeries({ pos_integration_id: this.pos.id })
        .then(result => {
          if (result && result.url) {
            tabOpen.location = result.url
          }
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    goToKountaOauth2() {
      let tabOpen = window.open('about:blank', 'newtab')

      this.loading = true
      api
        .kountaAuth({ pos_integration_id: this.pos.id })
        .then(result => {
          if (result && result.url) {
            tabOpen.location = result.url
          }
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    goToCloverOauth2() {
      let tabOpen = window.open('about:blank', 'newtab')

      this.loading = true
      api
        .cloverAuth({ pos_integration_id: this.pos.id })
        .then(result => {
          if (result && result.url) {
            tabOpen.location = result.url
          }
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    goToSquareOauth2() {
      let tabOpen = window.open('about:blank', 'newtab')

      this.loading = true
      api
        .squareAuth({ pos_integration_id: this.pos.id })
        .then(result => {
          if (result && result.url) {
            tabOpen.location = result.url
          }
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    onPOSTypeInput(item) {
      const duplicatePOSType = this.posIntegrations.filter(i => i.type === item.id),
        nonArchivedDuplicate = duplicatePOSType.find(i => !i.is_archived)
      this.archivedDuplicateIntegration = !nonArchivedDuplicate ? duplicatePOSType.find(i => i.is_archived) : null
      if (this.$refs.confirmDialog) {
        this.$refs.confirmDialog.confirm({
          callback: () => {
            let type = null
            if (this.$refs.inputGroup && this.$refs.inputGroup.reset) {
              this.$refs.inputGroup.reset()
            }

            let title = item.id
            if (duplicatePOSType.length) {
              title += ' ' + (duplicatePOSType.length + 1)
            }
            this.title = title
            this.hideTitle = !duplicatePOSType.length

            type = item.id

            this.selectedPOSType = type
            let extra = this.posExtraFields[type] || { data: {}, fields: {} }
            this.localPos = {
              type
            }
            this.currencyConversion = 0
            this.localPosParams = {
              ...extra.data
            }
            setTimeout(() => {
              this.extraFields = extra.fields
            }, 0)
          },
          cancelCallback: () => {
            this.archivedDuplicateIntegration = null
            this.$refs.posTypeSelector.$refs.multiselect.localValue = null
            this.localPosParams = {}
            this.extraFields = {}
            this.localPos = {}
            this.selectedPOSType = null
            this.title = ''
            this.currencyConversion = 0
            this.hideTitle = true
          },
          message: this.translations.translate(nonArchivedDuplicate ? 'confirmPOSIntegrationDuplicateDescription' : 'confirmPOSIntegrationDuplicateArchivedDescription', { '{a}': item.title }),
          title: this.translations.confirmPOSIntegrationDuplicateTitle,
          autoConfirm: !duplicatePOSType.length,
          okText: this.translations.txtPOSIntegrationCreateNew
        })
      }
    },
    setValidState(errorCount) {
      this.valid = !errorCount
    },
    setVeloceApiValidState(errorCount) {
      this.veloceApiValid = !errorCount
    },
    getVeloceApiLocations() {
      if (this.veloceApiLocations.length) {
        this.veloceApiLocationsVisible = true
      } else {
        this.loadingLocations = true
        api.posVeloceApiLocations().then(locations => {
          this.veloceApiLocations = locations
          this.veloceApiLocationsVisible = true
        }).finally(() => {
          this.loadingLocations = false
        })
      }
    },
    getVeloceApiDivisions() {
      if (this.localPosParams && this.localPosParams.location_id) {
        api.posVeloceApiLocationDivisions({ location_id: this.localPosParams.location_id }).then(divisions => {
          this.gridMultiSelectItemsMapper.veloceapiDivisions = divisions
        })
      }
    },
    onChange(value, type, fieldType, index, innerType) {
      console.log('value, type, index, innerType', value, type, index, innerType)
      console.log('this.localPosParams', this.localPosParams)
      if (fieldType === 'array' && innerType && this.localPosParams[type] && this.localPosParams[type][index]) {
        this.localPosParams[type][index][innerType] = value
      } else {
        this.localPosParams[type] = value
        this.localPosParams = { ...this.localPosParams }
      }
    },
    getCloverModifierGroups() {
      let data = {
        merchant_id: this.pos.client_code.merchant_id,
        api_key: this.pos.client_code.api_key
      }
      api.posCloverApiLocations(data).then(items => {
        this.gridMultiSelectItemsMapper.cloverModifiers = items
      })
    },
    rPowerStoreChanged(mid) {
      const store = this.rPowerStores.find(s => s.mid === mid)
      this.onChange(store.mid, 'mid')
      this.onChange(store.cg, 'cg')
    }
  },
  mounted() {
    if (this.pos && this.pos.type === 'clover' && this.pos.client_code && this.pos.client_code.api_key && this.pos.client_code.merchant_id) {
      this.getCloverModifierGroups()
    }
    this.posExtraFields = posExtraFields(this.translations)
    this.selectedPOSType = (this.pos && this.pos.type) || null
    let extra = this.posExtraFields[this.selectedPOSType] || { data: {}, fields: {} }

    this.localPos = {
      type: this.selectedPOSType
    }

    this.localPosParams = {
      ...extra.data,
      ...((this.pos && this.pos.client_code) || {})
    }

    this.title = this.pos?.title || ''
    this.currencyConversion = this.pos?.currency_conversion || 0

    this.extraFields = extra.fields
  },
  watch: {
    selectedTab() {
      if (this.selectedTab === 1 && !this.menuCategories.length) {
        api.getMenuCategories().then(response => {
          this.menuCategories = response
        })
      } else if (this.selectedTab === 2 && !this.saleCategories.length) {
        api.getSaleCategories().then(response => {
          this.saleCategories = response
        })
      }
    },
    'localPosParams.location_id': {
      handler() {
        if (this.selectedPOSType === 'veloceapi') {
          this.getVeloceApiDivisions()
        }
      }
    },
    selectedPOSType: {
      immediate: true,
      handler(selectedPOSType) {
        if (selectedPOSType === 'square' && this.pos) {
          api.posSquareLocations({ venue_id: this.venueComputed.id, pos_integration_id: this.pos.id }).then(locations => {
            this.gridMultiSelectItemsMapper.square = locations
            setTimeout(() => {
              if (this.$refs.inputGroup && this.$refs.inputGroup.reset) {
                this.$refs.inputGroup.reset()
              }
            }, 0)
            console.log('this.gridMultiSelectItemsMapper.square', this.gridMultiSelectItemsMapper.square)
          })
        }
        if (selectedPOSType === 'rpowerapi' && this.user.god_mode) {
          api.posRPowerStores().then(stores => {
            this.rPowerStores = stores
          })
        }
      }
    },
    selectedVeloceApiLocation(selectedVeloceApiLocation) {
      this.localPosParams.location_id = selectedVeloceApiLocation.id
      this.localPosParams.helperText = selectedVeloceApiLocation.title
    }
  }
}
</script>

<style lang="scss">
.pos-extra-field-remove {
  position: absolute;
  top: -5px;
  right: -10px;
  z-index: 1;
  background-color: transparent !important;
}

.clover-modifiers .ag-cell {
  margin-left: 11px;
}
</style>
