<template>
  <div class="wisk-modal-container" :class="{ 'modal-xtra-lg': extraLargeLocal, invisible }">
    <div class="modal fade wisk-modal" :class="{ 'd-block show': visibleLocal, 'full-screen': fullScreenLocal, 'no-footer': hideFooter }"
      :style="{ zIndex: zIndex }" @click="$event => $event.stopPropagation()">
      <div class="modal-dialog" :class="['modal-' + size, { ' modal-dialog-centered': centered }]">
        <div class="modal-content" tabindex="-1" role="document" :class="contentClass">
          <header v-if="visibleLocal || keepAlive" class="modal-header" :class="[{ 'd-none': hideHeader || (hideHeaderInner && !preventClose), 'p-1': hideHeaderInner }, headerClass]">
            <b-dropdown v-if="!hideHelpButton" left menuClass="mt- " size="xs" variant="link" toggle-class="" style="z-index: 100" class="modal-contextual-help" no-caret>
              <template v-slot:button-content>
                <icon name="wisk-help" :scale="0.7" />
              </template>

              <b-dropdown-item @click="goToHelpPage">
                <icon name="wisk-help-page" class="me-2 text-primary" :scale="0.7" />
                <span class="text-primary">{{ translations.txtMenuHelpPage }}</span>
              </b-dropdown-item>
              <b-dropdown-item @click="openContactUs">
                <icon name="wisk-email" class="me-2 text-primary" :scale="0.7" />
                <span class="text-primary">{{ translations.txtGenericContactUs }}</span>
              </b-dropdown-item>
            </b-dropdown>

            <slot name="modal-header">
              <b-container fluid>
                <b-row v-if="preventClose" :class="hideHeaderInner ? 'px-1' : 'pb-3 mb-4 border-primary border-bottom'">
                  <b-col v-if="venuesHighLevel?.length && venuesHighLevel.length > 1">
                    <venueSwitch class="intercom-sidebar_item-select_venue pt-2" data-intercom-target="intercom-sidebar_item-select_venue" size="sm" />
                  </b-col>
                  <b-col cols="4" style="padding-right: 70px;">
                    <b-button to="/logout" variant="link" class="float-end text-primary">
                      {{ translations.txtMenuSignOut }}
                      <icon name="wisk-log-out" :scale=".9" class="ms-1" />
                    </b-button>
                  </b-col>
                </b-row>

                <b-row :class="{ 'd-none': hideHeaderInner }">
                  <b-col class="" :class="{ 'bg-warning': warning }">
                    <slot name="modal-title">
                      <h5 class="modal-title"> {{ title }} </h5>
                    </slot>
                  </b-col>
                  <b-col :cols="12" :md="6" :class="{ 'd-none': hideHeaderExtra }" style="padding-right: 2rem !important;">
                    <slot name="modal-header-extra"></slot>
                  </b-col>
                </b-row>
                <button class="bt-modal-expand-collapse" v-if="!hideSizeButtons && !fullScreen && extraLargeLocal && fullScreenLocal" type="button" @click.stop="toggleFullscreen">
                  <icon class="text-secondary" name="wisk-compress" :scale="0.9" />
                </button>
                <button class="bt-modal-expand-collapse can-still-grow" v-if="!hideSizeButtons && !fullScreen && extraLargeLocal && !fullScreenLocal" type="button" @click.stop="toggleFullscreen">
                  <icon class="text-secondary" name="wisk-expand" :scale="0.9" />
                </button>
                <button class="bt-modal-expand-collapse" v-if="!hideSizeButtons && !fullScreen && !extraLargeLocal && !extraLarge && size === 'lg'" type="button" @click.stop="toggleExtraLarge">
                  <icon class="text-secondary" name="wisk-arrows-h" :scale="0.9" />
                </button>
                <button class="bt-modal-expand-collapse" v-if="!hideSizeButtons && !fullScreen && extraLargeLocal && !extraLarge && size === 'lg'" type="button" @click.stop="toggleExtraLarge">
                  <icon class="text-secondary" name="wisk-compress-alt" :scale="0.9" style="transform: rotate(45deg);" />
                </button>
                <button v-show="!preventClose" type="button" @click.stop="hide" @mouseenter="mouseOverClose = true" @mouseleave="mouseOverClose = false" aria-label="Close" class="close btn-close position-absolute">
                  <!-- <icon v-if="smile && mouseOverClose" class="text-success" name="regular/grin-wink" :scale="2" /> -->
                  <!-- <icon v-if="smile && mouseOverClose" class="text-success" name="regular/snowflake" :scale="2" /> -->
                  <icon class="text-secondary" name="wisk-exit" :scale="0.64" />
                </button>

              </b-container>
            </slot>
          </header>
          <div v-if="visibleLocal || keepAlive" class="modal-body" v-bind="$attrs" :class="[{ 'full-height': fullHeight, 'no-footer': hideFooter }, bodyClass]" :key="key">
            <slot></slot>
          </div>
          <footer class="modal-footer" v-if="!hideFooter">
            <slot name="modal-footer">
              <!-- <b-row> -->
              <b-col class="px-1" :class="{ 'd-none': hideFooterExtra }">
                <slot name="modal-footer-left-side"></slot>
              </b-col>
              <b-col class="px-1">
                <div class="float-end">
                  <b-button :disabled="okDisabled" class="wisk-modal-button me-2 mb-2" v-if="!hideOK" variant="primary" :size="buttonSize" @click.stop="ok">
                    {{ okTextLocal }}
                  </b-button>
                  <b-button :disabled="cancelDisabled" class="wisk-modal-button mb-2" v-if="!hideCancel && !preventClose" variant="secondary" :size="buttonSize" @click.stop="hide" v-tooltip="cancelText">
                    {{ cancelTextLocal }}
                  </b-button>
                </div>
              </b-col>

              <!-- </b-row> -->
            </slot>
          </footer>

          <div v-if="showNavigationLeft" class="middle-center modal-navigation modal-navigation-left" @click="$emit('goLeft')" v-tooltip="`${translations.txtGenericPrevious} (${keyboardNavigationKey} + Left)`">
            <icon class="text-secondary" name="wisk-chevron-left" :scale="0.9" />
          </div>
          <div v-if="showNavigationRight" class="middle-center modal-navigation modal-navigation-right" @click="$emit('goRight')" v-tooltip="`${translations.txtGenericNext} (${keyboardNavigationKey} + Right)`">
            <icon class="text-secondary" name="wisk-chevron-right" :scale="0.9" />
          </div>
        </div>
      </div>
    </div>
    <div @click.stop="hide" class="modal-backdrop fade wisk-modal-background" :class="{ 'show': visibleLocal }" :style="{ zIndex: zIndex - 1 }"></div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import helpDeskLinks from '@/modules/helpDeskLinks'
import venueSwitch from '@/components/venues/VenueSwitch'

export default {
  name: 'WiskModal',
  emits: ['update:modelValue', 'show', 'hide', 'ok', 'goLeft', 'goRight'],
  components: { venueSwitch },
  props: {
    modelValue: Boolean,
    keepAlive: Boolean,
    forceRefreshOnResize: Boolean,
    visible: Boolean,
    warning: Boolean,
    hideHelpButton: Boolean,
    showNavigationLeft: Boolean,
    showNavigationRight: Boolean,
    preventClose: Boolean,
    preventOKClose: Boolean,
    fullScreen: Boolean,
    extraLarge: Boolean,
    fullHeight: Boolean,
    title: String,
    buttonSize: String,
    okText: String,
    contentClass: String,
    bodyClass: String,
    headerClass: String,
    cancelText: String,
    hideOK: Boolean,
    hideFooter: Boolean,
    hideHeaderExtra: Boolean,
    hideFooterExtra: Boolean,
    hideCancel: Boolean,
    hideHeader: Boolean,
    hideHeaderInner: Boolean,
    okDisabled: Boolean,
    cancelDisabled: Boolean,
    centered: Boolean,
    invisible: Boolean,
    size: { type: String, default: 'md' },
    defaultZIndex: Number
  },
  data() {
    return {
      modalId: '',
      okTextLocal: '',
      fullScreenLocal: false,
      hideSizeButtons: false,
      extraLargeLocal: false,
      cancelTextLocal: '',
      modalCount: 0,
      visibleLocal: false,
      zIndex: 0,
      hideDebounceId: null,
      canHide: false,
      smile: false,
      debounceId: null,
      mouseOverClose: false,
      key: 1
    }
  },
  computed: {
    ...mapState(['wiskModalLayers', 'translations', 'venuesHighLevel', 'deviceInfo']),
    ...mapGetters(['deviceType']),
    keyboardNavigationKey() {
      if (this.deviceInfo?.os?.name === 'Mac OS') {
        return 'Ctrl + Option'
      }
      return 'Ctrl + Alt'
    }
  },
  methods: {
    ...mapActions(['getWiskModalNextLayer', 'releaseWiskModalLayer', 'setGlobalAction']),
    toggleExtraLarge() {
      this.extraLargeLocal = !this.extraLargeLocal

      if (this.forceRefreshOnResize) {
        this.key++
      }
    },
    toggleFullscreen() {
      this.fullScreenLocal = !this.fullScreenLocal

      if (this.forceRefreshOnResize) {
        this.key++
      }
    },
    show() {
      if (!this.visibleLocal) {
        this.getWiskModalNextLayer().then(result => {
          this.zIndex = this.defaultZIndex || result.zIndex
          this.modalId = result.id
          this.modalCount = result.count
          this.smile = !!result.smile
          document.addEventListener('keydown', this.escModalCloseEventHandler)
        })
        this.visibleLocal = true

        this.$emit('show')
        this.$emit('update:modelValue', true)
      }
    },
    escModalCloseEventHandler(event) {
      let key = event.key
      if (key === 'Escape') {
        let topLayer = this.wiskModalLayers[this.wiskModalLayers.length - 1]
        if (topLayer && topLayer.id && topLayer.id === this.modalId) {
          this.hide()
        }
      }
    },
    hide() {
      if (this.visibleLocal && !this.preventClose) {
        this.$emit('hide')
        this.$emit('update:modelValue', false)
        this.visibleLocal = false
        this.releaseWiskModalLayer()

        document.removeEventListener('keydown', this.escModalCloseEventHandler)
      }
    },
    ok() {
      if (!this.okDisabled) {
        this.$emit('ok')
        if (!this.preventOKClose) {
          setTimeout(() => {
            this.hide()
          }, 0)
        }
      }
    },
    handleStateChange() {
      clearTimeout(this.debounceId)
      this.debounceId = setTimeout(() => {
        if (this.modelValue || this.visible) {
          this.show()
        } else if (this.visibleLocal) {
          this.hide()
        }
        setTimeout(() => {
          this.setLabels()
        }, 0)
      }, 50)
    },
    setLabels() {
      this.okTextLocal = this.okText || this.translations.txtGenericOK
      this.cancelTextLocal = this.cancelText || this.translations.txtGenericCancel
      if (this.hideOK) {
        this.cancelTextLocal = this.translations.txtGenericClose
      }
    },
    goToHelpPage() {
      let helpDeskLink = helpDeskLinks[this.$route.name] || 'https://help.wisk.ai/en/'
      window.open(helpDeskLink, '_blank')
    },
    openContactUs() {
      this.setGlobalAction({ type: 'supportEmailForm', action: {} })
    },
    handleKeyboardNavigation(event) {
      const isCtrlOrCmd = event.ctrlKey || event.metaKey,
        isAltOrOpt = event.altKey

      if (isCtrlOrCmd && isAltOrOpt && event.key === 'ArrowLeft' && this.showNavigationLeft) {
        this.$emit('goLeft')
      } else if (isCtrlOrCmd && isAltOrOpt && event.key === 'ArrowRight' && this.showNavigationRight) {
        this.$emit('goRight')
      }
    }
  },
  mounted() {
    setTimeout(() => {
      if (this.showNavigationLeft || this.showNavigationRight) {
        document.addEventListener('keydown', this.handleKeyboardNavigation)
        document.body.classList.add('overflow-hidden') //needed because the button right is where the scrollbar is
      }
    }, 0)
  },
  beforeUnmount() {
    document.removeEventListener('keydown', this.escModalCloseEventHandler)
    clearTimeout(this.debounceId)

    if (this.showNavigationLeft || this.showNavigationRight) {
      document.removeEventListener('keydown', this.handleKeyboardNavigation)
      document.body.classList.remove('overflow-hidden')
    }
  },
  watch: {
    fullScreen: {
      immediate: true,
      handler(fullScreen) {
        this.fullScreenLocal = !!fullScreen
      }
    },
    extraLarge: {
      immediate: true,
      handler(extraLarge) {
        this.extraLargeLocal = !!extraLarge

        if ((this.deviceType === 'mobile' || this.deviceType === 'tablet') && this.extraLargeLocal) {
          this.fullScreenLocal = true
          this.hideSizeButtons = true
        }
      }
    },
    visible: {
      handler: 'handleStateChange',
      immediate: true
    },
    modelValue: {
      handler: 'handleStateChange',
      immediate: true
    }
  }
}
</script>

<style lang="scss" >
.modal-xtra-lg>.modal>.modal-dialog.modal-lg {
  max-width: 1196px;
}

@media (min-width: 768px) {
  .modal-lg {
    max-width: 740px;
  }
}

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px;
  }
}

.app.mobile,
.app.Mobile {
  .wisk-modal {
    padding-right: 0rem;
    padding-left: 0rem;

    max-width: 100vw;

    .modal-header {
      padding: 0.5rem;
      z-index: 9999;

      >.container-fluid {
        padding-right: 5px;
        padding-left: 5px;
      }

      .close {
        top: 4px;
        right: 4px;
      }

      .bt-modal-expand-collapse {
        display: none;
      }
    }

    .modal-footer {
      justify-content: center;
    }
  }
}

.app.tablet,
.app.Tablet {
  .wisk-modal {
    padding-right: 0rem;
    padding-left: 0rem;

    .modal-header {
      padding: 0.5rem;

      >.container-fluid {
        padding-right: 5px;
        padding-left: 5px;
      }

      .close {
        top: -8px;
        right: -8px;
      }
    }

    .modal-dialog {
      .modal-content {
        border-radius: 5px;
      }

      .modal-body {
        max-height: calc(100vh - 260px);
        overflow-y: auto;

        &.no-footer {
          max-height: calc(100vh - 215px);
          overflow-y: auto;
        }

        &.full-height {
          min-height: calc(100vh - 300px);
        }
      }
    }

    .modal-footer {
      justify-content: center;
    }
  }
}

.wisk-modal-container {
  &.invisible {
    opacity: 0;
  }

  .modal-header {
    padding-bottom: 0.5rem;
    padding-right: 50px;
    border: 0;
    box-shadow: 0px 2px 3px var(--gray-200);
    position: relative;
    z-index: 999;

    .close {
      opacity: 1;
      margin: 0;
      outline: none !important;
      padding: 0px;
      top: 8px;
      right: 7px;
      width: 28px;
      height: 28px;
      border: 1px solid var(--blue-300);

      .fa-icon {
        vertical-align: 1px !important;
      }

      &:hover {
        // opacity: 1 !important;
        // transform: scale(1.05);
        background-color: var(--blue-400);

        .fa-icon {
          color: white;
        }
      }
    }

    .bt-modal-expand-collapse {
      opacity: 1;
      position: absolute;
      background-color: white;
      border-radius: 50%;
      margin: 0;
      outline: none !important;
      padding: 5px;
      top: 8px;
      right: 45px;
      border: none;

      &:hover {
        opacity: 1 !important;
        transform: scale(1.15);
      }
    }
  }

  .modal-body {
    background-color: var(--blue-100);
  }

  .wisk-modal-background {
    display: none;

    /*
    necessary in order to jump out position fixed background from a position fixed container
    alternative would be to fix it with js
    */
    width: 10000px;
    height: 10000px;
    top: -2500px;
    left: -2500px;

    &.show {
      display: block;
    }
  }

  .wisk-modal {
    display: none;
    pointer-events: none;
    padding-right: 1rem;
    padding-left: 1rem;

    &.full-screen {
      padding-right: 10px;
      padding-left: 10px;
      padding-top: 10px;
    }

    .modal-dialog {
      .modal-content {
        border-radius: 5px;
      }

      .modal-body {
        max-height: calc(100vh - 210px);
        overflow-y: auto;

        &.no-footer {
          max-height: calc(100vh - 125px);
          overflow-y: auto;
        }

        &.full-height {
          min-height: calc(100vh - 200px);
          //padding-bottom: 30px;
        }
      }
    }

    .wisk-modal-button {
      min-width: 90px;
    }
  }
}

.modal-contextual-help {
  position: absolute;
  top: 3px;
  left: 3px;
  width: 15px;
  // border-radius: 7.5px;
  /* outline: 1px solid red; */
  height: 15px;
  padding: 0;
  background-color: white;

  .btn {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}

.wisk-modal-container.modal-xtra-lg .wisk-modal>.modal-dialog {
  >.modal-content {
    >.modal-header {
      .bt-modal-expand-collapse {
        &:hover {
          transform: scale(0.9);
        }

        &.can-still-grow {
          &:hover {
            transform: scale(1.15);
          }
        }
      }
    }
  }
}

//format is broken for scss, we'll have to come back and fix it
.wisk-modal-container .wisk-modal .modal-dialog .modal-content .modal-navigation {
  position: absolute;
  width: 20px;
  height: 140px;
  top: calc(50% - 70px);
  cursor: pointer;
  border-radius: 5px; //10px;
  background-color: var(--blue-100);
  // box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;

  &.modal-navigation-left {
    left: -10px;
    box-shadow: rgba(0, 0, 0, 0.15) -1.95px 1.95px 2.6px;
  }

  &.modal-navigation-right {
    right: -10px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  }

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  }

  &:active {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
}

.wisk-modal-container .wisk-modal.full-screen>.modal-dialog {
  $fullscreen-width: calc(100vw - 20px);
  $fullscreen-height: calc(100vh - 20px);

  width: $fullscreen-width;
  min-width: $fullscreen-width;
  max-width: $fullscreen-width;
  margin: 0;
  padding: 0;

  >.modal-content {
    height: $fullscreen-height;

    >.modal-header {
      // padding-right: 50px;

      // .close {
      //   margin-right: 15px;
      //   margin-top: 15px;
      // }
      .bt-modal-expand-collapse {
        &:hover {
          transform: scale(0.9);
        }
      }
    }

    >.modal-body {
      max-height: none;
      padding-bottom: 10px;
      padding-top: 10px;
    }
  }
}
</style>
